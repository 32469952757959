import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TourService } from '../tour.service';

@Component({
  selector: 'app-gototour',
  templateUrl: './gototour.component.html',
  styleUrls: ['./gototour.component.css', '../../../app.component.css']
})
export class GoToTourComponent implements OnInit {
  tourId;

  constructor(private route: ActivatedRoute, private tourService: TourService, private router: Router, @Inject(PLATFORM_ID) public platformId: Object) { }

  ngOnInit() {
    this.tourId = this.route.snapshot.params.tourId;
    this.getTour();
  }

  getTour() {
    this.tourService.getTour(this.tourId).then((tour) => {
      console.log(tour);
      if (tour) {
        const cityName = tour.city.name.toLowerCase();
        const countryName = tour.city.countryName.toLowerCase();
        this.router.navigate([`/tour/${countryName}/${cityName}/${this.tourId}`]);
      }
    });
  }
}

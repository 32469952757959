<div class="app-promo-banner" [ngClass]="{'makeSpaceForBanner': needSpaceForBanner}">
  <div class="container">
    <div class="row align-items-center">
      <!-- Left side: App Info -->
      <div class="col-lg-7 mb-4 mb-lg-0">
        <h2 class="promo-title">Explore Cities Like Never Before</h2>
        <p class="promo-subtitle">Download the AllTours app and start understanding cities like never before</p>

        <!-- Reviews Section -->
        <div class="reviews-section">
          <div class="overall-rating">
            <div class="stars">
              <span class="material-symbols-rounded">star_rate</span>
              <span class="material-symbols-rounded">star_rate</span>
              <span class="material-symbols-rounded">star_rate</span>
              <span class="material-symbols-rounded">star_rate</span>
              <span class="material-symbols-rounded">star_half</span>
              <span class="rating-text">4.8 / 5.0</span>
            </div>
          </div>

          <div class="row user-reviews">
            <!-- First review - always visible -->
            <div class="col-md-6 mb-3">
              <ng-container *ngTemplateOutlet="reviewCard; context: { $implicit: reviews[0] }">
              </ng-container>
            </div>
            <!-- Second review - hidden on mobile -->
            <div class="col-md-6 mb-3 d-none d-md-block">
              <ng-container *ngTemplateOutlet="reviewCard; context: { $implicit: reviews[1] }">
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Features and Screenshot Row for Mobile -->
        <div class="row d-md-none align-items-center mb-4">
          <div class="col-7">
            <!-- Feature Points -->
            <div class="feature-points">
              <div class="feature mb-3">
                <span class="material-symbols-rounded feature-icon">headphones</span>
                <span>Entertaining Tours</span>
              </div>
              <div class="feature mb-3">
                <span class="material-symbols-rounded feature-icon">offline_pin</span>
                <span>Works Offline</span>
              </div>
              <div class="feature">
                <span class="material-symbols-rounded feature-icon">map</span>
                <span>Interactive Maps</span>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="app-preview">
              <img src="assets/images/alltours/converted/screenshots/screenshot_0-800w.webp"
                alt="allTours App Screenshot" class="app-screenshot">
            </div>
          </div>
        </div>

        <!-- Feature Points for Desktop -->
        <div class="feature-points d-none d-md-flex">
          <div>
            <div class="feature">
              <span class="material-symbols-rounded feature-icon">headphones</span>
              <span>Professional Audio Tours</span>
            </div>
          </div>
          <div>
            <div class="feature">
              <span class="material-symbols-rounded feature-icon">offline_pin</span>
              <span>Works Offline</span>
            </div>
          </div>
          <div>
            <div class="feature">
              <span class="material-symbols-rounded feature-icon">map</span>
              <span>Interactive Maps</span>
            </div>
          </div>
        </div>

        <div class="download-buttons d-none d-md-flex">
          <div class="store-button">
            <picture>
              <source srcset="../../../assets/images/alltours/converted/appStoreButton.webp" type="image/webp" />
              <img src="../../../assets/images/alltours/converted/appStoreButton.png" (click)="goToAppStore()"
                alt="Download on App Store" loading="lazy" style="height: 48px; width: auto; cursor: pointer;" />
            </picture>
          </div>
          <div class="store-button">
            <picture>
              <source srcset="../../../assets/images/alltours/converted/googlePlayButton.webp" type="image/webp" />
              <img src="../../../assets/images/alltours/converted/googlePlayButton.png" (click)="goToPlayStore()"
                alt="Get it on Google Play" loading="lazy" style="height: 48px; width: auto; cursor: pointer;" />
            </picture>
          </div>
        </div>

        <!-- Get App Button - Mobile -->
        <div class="d-md-none" style="display: flex; justify-content: center;">
          <button class="ctaBtn" (click)="getApp()" style="margin: auto">
            Get App
          </button>
        </div>
      </div>

      <!-- Right side: App Screenshot for Desktop -->
      <div class="col-lg-5 d-none d-md-block">
        <div class="app-preview">
          <img src="assets/images/alltours/converted/screenshots/screenshot_0-800w.webp" alt="allTours App Screenshot"
            class="app-screenshot">
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Review Card Template -->
<ng-template #reviewCard let-review>
  <div class="review-card">
    <div class="review-stars">
      <span class="material-symbols-rounded" *ngFor="let star of [].constructor(review.rating)">star_rate</span>
    </div>
    <p class="review-text">"{{ review.text }}"</p>
    <p class="reviewer">- {{ review.author }}</p>
  </div>
</ng-template>
import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute } from '@angular/router';
import { getFunctions, httpsCallableData } from '@angular/fire/functions';
import { TourService } from '../tour.service';
import { isPlatformBrowser } from '@angular/common';
import { logEventMethod } from 'src/app/utils/logging';

@Component({
  selector: 'app-bokun-purchase',
  templateUrl: './bokun-purchase.component.html',
  styleUrls: ['./bokun-purchase.component.css', '../../../app.component.css']
})
export class BokunPurchaseComponent implements OnInit {
  bookingId: string;
  purchases = []
  copiedCodes: { [key: string]: boolean } = {};
  isIphone = false;
  storeQrCode = 'appstoreqrcode';

  constructor(private route: ActivatedRoute, private tourService: TourService) { }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.params.bookingId;
    this.getPurchases(this.bookingId);
    this.isIphone = navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i) != null;
    this.storeQrCode = this.isIphone ? 'appstoreqrcode' : 'playstoreqrcode';
  }

  async getPurchases(bookingId: string) {
    try {
      const getPurchases = httpsCallableData(getFunctions(), 'getBokunBooking');
      const result: any = await getPurchases({ bookingId: bookingId }).toPromise();
      console.log("Raw result:", result);

      if (result && result.items) {
        this.purchases = result.items;
        console.log("Purchases array:", this.purchases);

        for (const purchase of this.purchases) {
          const tour = await this.tourService.getTour(purchase.tourId);
          purchase.tour = tour;
          purchase.status = "confirmed";
          this.copiedCodes[purchase.redemptionCode] = false;
        }
      } else {
        console.error("Unexpected response format:", result);
        this.purchases = [];
      }
    } catch (error) {
      console.error("Error fetching purchases:", error);
      this.purchases = [];
    }
  }

  copyCode(redemptionCode: string): void {
    navigator.clipboard.writeText(redemptionCode).then(() => {
      this.copiedCodes[redemptionCode] = true;
      setTimeout(() => {
        this.copiedCodes[redemptionCode] = false;
      }, 2000);
    });
  }

  goToApp() {
    if (this.isIphone) {
      logEventMethod('post_purchase_download_click', { type: 'ios' });
      window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
    } else {
      logEventMethod('post_purchase_download_click', { type: 'not-ios' });
      window.open('https://play.google.com/store/apps/details?id=app.alltours');
    }
  }
}

{
  "JP": {
    "en": "Japan",
    "es": "Japón",
    "fr": "Japon",
    "zh": "日本",
    "de": "Japan",
    "it": "Giappone",
    "id": "Jepang",
    "ja": "日本",
    "th": "ญี่ปุ่น",
    "pt": "Japão"
  },
  "ID": {
    "en": "Indonesia",
    "es": "Indonesia",
    "fr": "Indonésie",
    "zh": "印度尼西亚",
    "de": "Indonesien",
    "it": "Indonesia",
    "id": "Indonesia",
    "ja": "インドネシア",
    "th": "อินโดนีเซีย",
    "pt": "Indonésia"
  },
  "IN": {
    "en": "India",
    "es": "India",
    "fr": "Inde",
    "zh": "印度",
    "de": "Indien",
    "it": "India",
    "id": "India",
    "ja": "インド",
    "th": "อินเดีย",
    "pt": "Índia"
  },
  "PH": {
    "en": "Philippines",
    "es": "Filipinas",
    "fr": "Philippines",
    "zh": "菲律宾",
    "de": "Philippinen",
    "it": "Filippine",
    "id": "Filipina",
    "ja": "フィリピン",
    "th": "ฟิลิปปินส์",
    "pt": "Filipinas"
  },
  "BR": {
    "en": "Brazil",
    "es": "Brasil",
    "fr": "Brésil",
    "zh": "巴西",
    "de": "Brasilien",
    "it": "Brasile",
    "id": "Brasil",
    "ja": "ブラジル",
    "th": "บราซิล",
    "pt": "Brasil"
  },
  "KR": {
    "en": "South Korea",
    "es": "Corea del Sur",
    "fr": "Corée du Sud",
    "zh": "韩国",
    "de": "Südkorea",
    "it": "Corea del Sud",
    "id": "Korea Selatan",
    "ja": "韓国",
    "th": "เกาหลีใต้",
    "pt": "Coreia do Sul"
  },
  "CN": {
    "en": "China",
    "es": "China",
    "fr": "Chine",
    "zh": "中国",
    "de": "China",
    "it": "Cina",
    "id": "Tiongkok",
    "ja": "中国",
    "th": "จีน",
    "pt": "China"
  },
  "MX": {
    "en": "Mexico",
    "es": "México",
    "fr": "Mexique",
    "zh": "墨西哥",
    "de": "Mexiko",
    "it": "Messico",
    "id": "Meksiko",
    "ja": "メキシコ",
    "th": "เม็กซิโก",
    "pt": "México"
  },
  "EG": {
    "en": "Egypt",
    "es": "Egipto",
    "fr": "Égypte",
    "zh": "埃及",
    "de": "Ägypten",
    "it": "Egitto",
    "id": "Mesir",
    "ja": "エジプト",
    "th": "อียิปต์",
    "pt": "Egito"
  },
  "US": {
    "en": "United States",
    "es": "Estados Unidos",
    "fr": "États-Unis",
    "zh": "美国",
    "de": "Vereinigte Staaten",
    "it": "Stati Uniti",
    "id": "Amerika Serikat",
    "ja": "アメリカ合衆国",
    "th": "สหรัฐอเมริกา",
    "pt": "Estados Unidos"
  },
  "RU": {
    "en": "Russia",
    "es": "Rusia",
    "fr": "Russie",
    "zh": "俄罗斯",
    "de": "Russland",
    "it": "Russia",
    "id": "Rusia",
    "ja": "ロシア",
    "th": "รัสเซีย",
    "pt": "Rússia"
  },
  "TH": {
    "en": "Thailand",
    "es": "Tailandia",
    "fr": "Thaïlande",
    "zh": "泰国",
    "de": "Thailand",
    "it": "Thailandia",
    "id": "Thailand",
    "ja": "タイ",
    "th": "ไทย",
    "pt": "Tailândia"
  },
  "BD": {
    "en": "Bangladesh",
    "es": "Bangladés",
    "fr": "Bangladesh",
    "zh": "孟加拉国",
    "de": "Bangladesch",
    "it": "Bangladesh",
    "id": "Bangladesh",
    "ja": "バングラデシュ",
    "th": "บังกลาเทศ",
    "pt": "Bangladesh"
  },
  "AR": {
    "en": "Argentina",
    "es": "Argentina",
    "fr": "Argentine",
    "zh": "阿根廷",
    "de": "Argentinien",
    "it": "Argentina",
    "id": "Argentina",
    "ja": "アルゼンチン",
    "th": "อาร์เจนตินา",
    "pt": "Argentina"
  },
  "NG": {
    "en": "Nigeria",
    "es": "Nigeria",
    "fr": "Nigéria",
    "zh": "尼日利亚",
    "de": "Nigeria",
    "it": "Nigeria",
    "id": "Nigeria",
    "ja": "ナイジェリア",
    "th": "ไนจีเรีย",
    "pt": "Nigéria"
  },
  "TR": {
    "en": "Turkey",
    "es": "Turquía",
    "fr": "Turquie",
    "zh": "土耳其",
    "de": "Türkei",
    "it": "Turchia",
    "id": "Turki",
    "ja": "トルコ",
    "th": "ตุรกี",
    "pt": "Turquia"
  },
  "PK": {
    "en": "Pakistan",
    "es": "Pakistán",
    "fr": "Pakistan",
    "zh": "巴基斯坦",
    "de": "Pakistan",
    "it": "Pakistan",
    "id": "Pakistan",
    "ja": "パキスタン",
    "th": "ปากีสถาน",
    "pt": "Paquistão"
  },
  "CD": {
    "en": "Congo - Kinshasa",
    "es": "República Democrática del Congo",
    "fr": "Congo-Kinshasa",
    "zh": "刚果（金）",
    "de": "Kongo-Kinshasa",
    "it": "Congo - Kinshasa",
    "id": "Kongo - Kinshasa",
    "ja": "コンゴ民主共和国(キンシャサ)",
    "th": "คองโก - กินชาซา",
    "pt": "Congo - Kinshasa"
  },
  "VN": {
    "en": "Vietnam",
    "es": "Vietnam",
    "fr": "Vietnam",
    "zh": "越南",
    "de": "Vietnam",
    "it": "Vietnam",
    "id": "Vietnam",
    "ja": "ベトナム",
    "th": "เวียดนาม",
    "pt": "Vietnã"
  },
  "IR": {
    "en": "Iran",
    "es": "Irán",
    "fr": "Iran",
    "zh": "伊朗",
    "de": "Iran",
    "it": "Iran",
    "id": "Iran",
    "ja": "イラン",
    "th": "อิหร่าน",
    "pt": "Irã"
  },
  "GB": {
    "en": "United Kingdom",
    "es": "Reino Unido",
    "fr": "Royaume-Uni",
    "zh": "英国",
    "de": "Vereinigtes Königreich",
    "it": "Regno Unito",
    "id": "Inggris Raya",
    "ja": "イギリス",
    "th": "สหราชอาณาจักร",
    "pt": "Reino Unido"
  },
  "FR": {
    "en": "France",
    "es": "Francia",
    "fr": "France",
    "zh": "法国",
    "de": "Frankreich",
    "it": "Francia",
    "id": "Prancis",
    "ja": "フランス",
    "th": "ฝรั่งเศส",
    "pt": "França"
  },
  "PE": {
    "en": "Peru",
    "es": "Perú",
    "fr": "Pérou",
    "zh": "秘鲁",
    "de": "Peru",
    "it": "Perù",
    "id": "Peru",
    "ja": "ペルー",
    "th": "เปรู",
    "pt": "Peru"
  },
  "AO": {
    "en": "Angola",
    "es": "Angola",
    "fr": "Angola",
    "zh": "安哥拉",
    "de": "Angola",
    "it": "Angola",
    "id": "Angola",
    "ja": "アンゴラ",
    "th": "แองโกลา",
    "pt": "Angola"
  },
  "MY": {
    "en": "Malaysia",
    "es": "Malasia",
    "fr": "Malaisie",
    "zh": "马来西亚",
    "de": "Malaysia",
    "it": "Malaysia",
    "id": "Malaysia",
    "ja": "マレーシア",
    "th": "มาเลเซีย",
    "pt": "Malásia"
  },
  "CO": {
    "en": "Colombia",
    "es": "Colombia",
    "fr": "Colombie",
    "zh": "哥伦比亚",
    "de": "Kolumbien",
    "it": "Colombia",
    "id": "Kolombia",
    "ja": "コロンビア",
    "th": "โคลอมเบีย",
    "pt": "Colômbia"
  },
  "TZ": {
    "en": "Tanzania",
    "es": "Tanzania",
    "fr": "Tanzanie",
    "zh": "坦桑尼亚",
    "de": "Tansania",
    "it": "Tanzania",
    "id": "Tanzania",
    "ja": "タンザニア",
    "th": "แทนซาเนีย",
    "pt": "Tanzânia"
  },
  "HK": {
    "en": "Hong Kong",
    "es": "Hong Kong",
    "fr": "Hong Kong",
    "zh": "香港",
    "de": "Hongkong",
    "it": "Hong Kong",
    "id": "Hong Kong",
    "ja": "香港",
    "th": "ฮ่องกง",
    "pt": "Hong Kong"
  },
  "CL": {
    "en": "Chile",
    "es": "Chile",
    "fr": "Chili",
    "zh": "智利",
    "de": "Chile",
    "it": "Cile",
    "id": "Cile",
    "ja": "チリ",
    "th": "ชิลี",
    "pt": "Chile"
  },
  "SA": {
    "en": "Saudi Arabia",
    "es": "Arabia Saudí",
    "fr": "Arabie saoudite",
    "zh": "沙特阿拉伯",
    "de": "Saudi-Arabien",
    "it": "Arabia Saudita",
    "id": "Arab Saudi",
    "ja": "サウジアラビア",
    "th": "ซาอุดีอาระเบีย",
    "pt": "Arábia Saudita"
  },
  "IQ": {
    "en": "Iraq",
    "es": "Irak",
    "fr": "Irak",
    "zh": "伊拉克",
    "de": "Irak",
    "it": "Iraq",
    "id": "Irak",
    "ja": "イラク",
    "th": "อิรัก",
    "pt": "Iraque"
  },
  "SD": {
    "en": "Sudan",
    "es": "Sudán",
    "fr": "Soudan",
    "zh": "苏丹",
    "de": "Sudan",
    "it": "Sudan",
    "id": "Sudan",
    "ja": "スーダン",
    "th": "ซูดาน",
    "pt": "Sudão"
  },
  "ES": {
    "en": "Spain",
    "es": "España",
    "fr": "Espagne",
    "zh": "西班牙",
    "de": "Spanien",
    "it": "Spagna",
    "id": "Spanyol",
    "ja": "スペイン",
    "th": "สเปน",
    "pt": "Espanha"
  },
  "KE": {
    "en": "Kenya",
    "es": "Kenia",
    "fr": "Kenya",
    "zh": "肯尼亚",
    "de": "Kenia",
    "it": "Kenya",
    "id": "Kenya",
    "ja": "ケニア",
    "th": "เคนยา",
    "pt": "Quênia"
  },
  "MM": {
    "en": "Myanmar",
    "es": "Myanmar",
    "fr": "Myanmar",
    "zh": "缅甸",
    "de": "Myanmar",
    "it": "Myanmar",
    "id": "Myanmar",
    "ja": "ミャンマー",
    "th": "ไม่นมาร์",
    "pt": "Mianmar"
  },
  "CA": {
    "en": "Canada",
    "es": "Canadá",
    "fr": "Canada",
    "zh": "加拿大",
    "de": "Kanada",
    "it": "Canada",
    "id": "Kanada",
    "ja": "カナダ",
    "th": "แคนาดา",
    "pt": "Canadá"
  },
  "SG": {
    "en": "Singapore",
    "es": "Singapur",
    "fr": "Singapour",
    "zh": "新加坡",
    "de": "Singapur",
    "it": "Singapore",
    "id": "Singapura",
    "ja": "シンガポール",
    "th": "สิงคโปร์",
    "pt": "Singapura"
  },
  "CI": {
    "en": "Côte d’Ivoire",
    "es": "Côte d’Ivoire",
    "fr": "Côte d’Ivoire",
    "zh": "科特迪瓦",
    "de": "Côte d’Ivoire",
    "it": "Costa d’Avorio",
    "id": "Côte d’Ivoire",
    "ja": "コートジボワール",
    "th": "โกตดิวัวร์",
    "pt": "Costa do Marfim"
  },
  "AU": {
    "en": "Australia",
    "es": "Australia",
    "fr": "Australie",
    "zh": "澳大利亚",
    "de": "Australien",
    "it": "Australia",
    "id": "Australia",
    "ja": "オーストラリア",
    "th": "ออสเตรเลีย",
    "pt": "Austrália"
  },
  "ZA": {
    "en": "South Africa",
    "es": "Sudáfrica",
    "fr": "Afrique du Sud",
    "zh": "南非",
    "de": "Südafrika",
    "it": "Sudafrica",
    "id": "Afrika Selatan",
    "ja": "南アフリカ",
    "th": "แอฟริกาใต้",
    "pt": "África do Sul"
  },
  "MA": {
    "en": "Morocco",
    "es": "Marruecos",
    "fr": "Maroc",
    "zh": "摩洛哥",
    "de": "Marokko",
    "it": "Marocco",
    "id": "Maroko",
    "ja": "モロッコ",
    "th": "โมร็อกโก",
    "pt": "Marrocos"
  },
  "AF": {
    "en": "Afghanistan",
    "es": "Afganistán",
    "fr": "Afghanistan",
    "zh": "阿富汗",
    "de": "Afghanistan",
    "it": "Afghanistan",
    "id": "Afganistan",
    "ja": "アフガニスタン",
    "th": "อัฟกานิสถาน",
    "pt": "Afeganistão"
  },
  "JO": {
    "en": "Jordan",
    "es": "Jordania",
    "fr": "Jordanie",
    "zh": "约旦",
    "de": "Jordanien",
    "it": "Giordania",
    "id": "Yordania",
    "ja": "ヨルダン",
    "th": "จอร์แดน",
    "pt": "Jordânia"
  },
  "DE": {
    "en": "Germany",
    "es": "Alemania",
    "fr": "Allemagne",
    "zh": "德国",
    "de": "Deutschland",
    "it": "Germania",
    "id": "Jerman",
    "ja": "ドイツ",
    "th": "เยอรมนี",
    "pt": "Alemanha"
  },
  "DZ": {
    "en": "Algeria",
    "es": "Argelia",
    "fr": "Algérie",
    "zh": "阿尔及利亚",
    "de": "Algerien",
    "it": "Algeria",
    "id": "Aljazair",
    "ja": "アルジェリア",
    "th": "แอลจีเรีย",
    "pt": "Argélia"
  },
  "BO": {
    "en": "Bolivia",
    "es": "Bolivia",
    "fr": "Bolivie",
    "zh": "玻利维亚",
    "de": "Bolivien",
    "it": "Bolivia",
    "id": "Bolivia",
    "ja": "ボリビア",
    "th": "โบลิเวีย",
    "pt": "Bolívia"
  },
  "ET": {
    "en": "Ethiopia",
    "es": "Etiopía",
    "fr": "Éthiopie",
    "zh": "埃塞俄比亚",
    "de": "Äthiopien",
    "it": "Etiopia",
    "id": "Etiopia",
    "ja": "エチオピア",
    "th": "เอธิโอเปีย",
    "pt": "Etiópia"
  },
  "KW": {
    "en": "Kuwait",
    "es": "Kuwait",
    "fr": "Koweït",
    "zh": "科威特",
    "de": "Kuwait",
    "it": "Kuwait",
    "id": "Kuwait",
    "ja": "クウェート",
    "th": "คูเวต",
    "pt": "Kuwait"
  },
  "UA": {
    "en": "Ukraine",
    "es": "Ucrania",
    "fr": "Ukraine",
    "zh": "乌克兰",
    "de": "Ukraine",
    "it": "Ucraina",
    "id": "Ukraina",
    "ja": "ウクライナ",
    "th": "ยูเครน",
    "pt": "Ucrânia"
  },
  "YE": {
    "en": "Yemen",
    "es": "Yemen",
    "fr": "Yémen",
    "zh": "也门",
    "de": "Jemen",
    "it": "Yemen",
    "id": "Yaman",
    "ja": "イエメン",
    "th": "เยเมน",
    "pt": "Iêmen"
  },
  "GT": {
    "en": "Guatemala",
    "es": "Guatemala",
    "fr": "Guatemala",
    "zh": "危地马拉",
    "de": "Guatemala",
    "it": "Guatemala",
    "id": "Guatemala",
    "ja": "グアテマラ",
    "th": "กัวเตมาลา",
    "pt": "Guatemala"
  },
  "IT": {
    "en": "Italy",
    "es": "Italia",
    "fr": "Italie",
    "zh": "意大利",
    "de": "Italien",
    "it": "Italia",
    "id": "Italia",
    "ja": "イタリア",
    "th": "อิตาลี",
    "pt": "Itália"
  },
  "KP": {
    "en": "North Korea",
    "es": "Corea del Norte",
    "fr": "Corée du Nord",
    "zh": "朝鲜",
    "de": "Nordkorea",
    "it": "Corea del Nord",
    "id": "Korea Utara",
    "ja": "北朝鮮",
    "th": "เกาหลีเหนือ",
    "pt": "Coreia do Norte"
  },
  "TW": {
    "en": "Taiwan",
    "es": "Taiwán",
    "fr": "Taïwan",
    "zh": "台湾",
    "de": "Taiwan",
    "it": "Taiwan",
    "id": "Taiwan",
    "ja": "台湾",
    "th": "ไต้หวัน",
    "pt": "Taiwan"
  },
  "EC": {
    "en": "Ecuador",
    "es": "Ecuador",
    "fr": "Équateur",
    "zh": "厄瓜多尔",
    "de": "Ecuador",
    "it": "Ecuador",
    "id": "Ekuador",
    "ja": "エクアドル",
    "th": "เอกวาดอร์",
    "pt": "Equador"
  },
  "MG": {
    "en": "Madagascar",
    "es": "Madagascar",
    "fr": "Madagascar",
    "zh": "马达加斯加",
    "de": "Madagaskar",
    "it": "Madagascar",
    "id": "Madagaskar",
    "ja": "マダガスカル",
    "th": "มาดากัสการ์",
    "pt": "Madagascar"
  },
  "DO": {
    "en": "Dominican Republic",
    "es": "República Dominicana",
    "fr": "République dominicaine",
    "zh": "多米尼加共和国",
    "de": "Dominikanische Republik",
    "it": "Repubblica Dominicana",
    "id": "Republik Dominika",
    "ja": "ドミニカ共和国",
    "th": "สาธารณรัฐโดมินิกัน",
    "pt": "República Dominicana"
  },
  "AE": {
    "en": "United Arab Emirates",
    "es": "Emiratos Árabes Unidos",
    "fr": "Émirats arabes unis",
    "zh": "阿拉伯联合酋长国",
    "de": "Vereinigte Arabische Emirate",
    "it": "Emirati Arabi Uniti",
    "id": "Uni Emirat Arab",
    "ja": "アラブ首長国連邦",
    "th": "สหรัฐอาหรับเอมิเรตส์",
    "pt": "Emirados Árabes Unidos"
  },
  "UZ": {
    "en": "Uzbekistan",
    "es": "Uzbekistán",
    "fr": "Ouzbékistan",
    "zh": "乌兹别克斯坦",
    "de": "Usbekistan",
    "it": "Uzbekistan",
    "id": "Uzbekistan",
    "ja": "ウズベキスタン",
    "th": "อุซเบกิสถาน",
    "pt": "Uzbequistão"
  },
  "BF": {
    "en": "Burkina Faso",
    "es": "Burkina Faso",
    "fr": "Burkina Faso",
    "zh": "布基纳法索",
    "de": "Burkina Faso",
    "it": "Burkina Faso",
    "id": "Burkina Faso",
    "ja": "ブルキナファソ",
    "th": "บูร์กินาฟาโซ",
    "pt": "Burquina Faso"
  },
  "CM": {
    "en": "Cameroon",
    "es": "Camerún",
    "fr": "Cameroun",
    "zh": "喀麦隆",
    "de": "Kamerun",
    "it": "Camerun",
    "id": "Kamerun",
    "ja": "カメルーン",
    "th": "แคเมอรูน",
    "pt": "Camarões"
  },
  "GH": {
    "en": "Ghana",
    "es": "Ghana",
    "fr": "Ghana",
    "zh": "加纳",
    "de": "Ghana",
    "it": "Ghana",
    "id": "Ghana",
    "ja": "ガーナ",
    "th": "กานา",
    "pt": "Gana"
  },
  "AZ": {
    "en": "Azerbaijan",
    "es": "Azerbaiyán",
    "fr": "Azerbaïdjan",
    "zh": "阿塞拜疆",
    "de": "Aserbaidschan",
    "it": "Azerbaigian",
    "id": "Azerbaijan",
    "ja": "アゼルバイジャン",
    "th": "อาเซอร์ไบจาน",
    "pt": "Azerbaijão"
  },
  "ZW": {
    "en": "Zimbabwe",
    "es": "Zimbabue",
    "fr": "Zimbabwe",
    "zh": "津巴布韦",
    "de": "Simbabwe",
    "it": "Zimbabwe",
    "id": "Zimbabwe",
    "ja": "ジンバブエ",
    "th": "ซิมบับเว",
    "pt": "Zimbábue"
  },
  "CU": {
    "en": "Cuba",
    "es": "Cuba",
    "fr": "Cuba",
    "zh": "古巴",
    "de": "Kuba",
    "it": "Cuba",
    "id": "Kuba",
    "ja": "キューバ",
    "th": "คิวบา",
    "pt": "Cuba"
  },
  "KH": {
    "en": "Cambodia",
    "es": "Camboya",
    "fr": "Cambodge",
    "zh": "柬埔寨",
    "de": "Kambodscha",
    "it": "Cambogia",
    "id": "Kamboja",
    "ja": "カンボジア",
    "th": "กัมพูชา",
    "pt": "Camboja"
  },
  "SO": {
    "en": "Somalia",
    "es": "Somalia",
    "fr": "Somalie",
    "zh": "索马里",
    "de": "Somalia",
    "it": "Somalia",
    "id": "Somalia",
    "ja": "ソマリア",
    "th": "โซมาเลีย",
    "pt": "Somália"
  },
  "BY": {
    "en": "Belarus",
    "es": "Bielorrusia",
    "fr": "Biélorussie",
    "zh": "白俄罗斯",
    "de": "Belarus",
    "it": "Bielorussia",
    "id": "Belarus",
    "ja": "ベラルーシ",
    "th": "เบลารุส",
    "pt": "Bielorrússia"
  },
  "ML": {
    "en": "Mali",
    "es": "Mali",
    "fr": "Mali",
    "zh": "马里",
    "de": "Mali",
    "it": "Mali",
    "id": "Mali",
    "ja": "マリ",
    "th": "มาลี",
    "pt": "Mali"
  },
  "VE": {
    "en": "Venezuela",
    "es": "Venezuela",
    "fr": "Venezuela",
    "zh": "委内瑞拉",
    "de": "Venezuela",
    "it": "Venezuela",
    "id": "Venezuela",
    "ja": "ベネズエラ",
    "th": "เวเนซุเอลา",
    "pt": "Venezuela"
  },
  "KZ": {
    "en": "Kazakhstan",
    "es": "Kazajistán",
    "fr": "Kazakhstan",
    "zh": "哈萨克斯坦",
    "de": "Kasachstan",
    "it": "Kazakistan",
    "id": "Kazakstan",
    "ja": "カザフスタン",
    "th": "คาซัคสถาน",
    "pt": "Cazaquistão"
  },
  "SY": {
    "en": "Syria",
    "es": "Siria",
    "fr": "Syrie",
    "zh": "叙利亚",
    "de": "Syrien",
    "it": "Siria",
    "id": "Suriah",
    "ja": "シリア",
    "th": "ซีเรีย",
    "pt": "Síria"
  },
  "AT": {
    "en": "Austria",
    "es": "Austria",
    "fr": "Autriche",
    "zh": "奥地利",
    "de": "Österreich",
    "it": "Austria",
    "id": "Austria",
    "ja": "オーストリア",
    "th": "ออสเตรีย",
    "pt": "Áustria"
  },
  "MW": {
    "en": "Malawi",
    "es": "Malaui",
    "fr": "Malawi",
    "zh": "马拉维",
    "de": "Malawi",
    "it": "Malawi",
    "id": "Malawi",
    "ja": "マラウイ",
    "th": "มาลาวี",
    "pt": "Malaui"
  },
  "RO": {
    "en": "Romania",
    "es": "Rumanía",
    "fr": "Roumanie",
    "zh": "罗马尼亚",
    "de": "Rumänien",
    "it": "Romania",
    "id": "Rumania",
    "ja": "ルーマニア",
    "th": "โรมาเนีย",
    "pt": "Romênia"
  },
  "CG": {
    "en": "Congo - Brazzaville",
    "es": "Congo",
    "fr": "Congo-Brazzaville",
    "zh": "刚果（布）",
    "de": "Kongo-Brazzaville",
    "it": "Congo-Brazzaville",
    "id": "Kongo - Brazzaville",
    "ja": "コンゴ共和国(ブラザビル)",
    "th": "คองโก - บราซซาวิล",
    "pt": "República do Congo"
  },
  "PL": {
    "en": "Poland",
    "es": "Polonia",
    "fr": "Pologne",
    "zh": "波兰",
    "de": "Polen",
    "it": "Polonia",
    "id": "Polandia",
    "ja": "ポーランド",
    "th": "โปแลนด์",
    "pt": "Polônia"
  },
  "BE": {
    "en": "Belgium",
    "es": "Bélgica",
    "fr": "Belgique",
    "zh": "比利时",
    "de": "Belgien",
    "it": "Belgio",
    "id": "Belgia",
    "ja": "ベルギー",
    "th": "เบลเยียม",
    "pt": "Bélgica"
  },
  "ZM": {
    "en": "Zambia",
    "es": "Zambia",
    "fr": "Zambie",
    "zh": "赞比亚",
    "de": "Sambia",
    "it": "Zambia",
    "id": "Zambia",
    "ja": "ザンビア",
    "th": "แซมเบีย",
    "pt": "Zâmbia"
  },
  "HU": {
    "en": "Hungary",
    "es": "Hungría",
    "fr": "Hongrie",
    "zh": "匈牙利",
    "de": "Ungarn",
    "it": "Ungheria",
    "id": "Hungaria",
    "ja": "ハンガリー",
    "th": "ฮังการี",
    "pt": "Hungria"
  },
  "GN": {
    "en": "Guinea",
    "es": "Guinea",
    "fr": "Guinée",
    "zh": "几内亚",
    "de": "Guinea",
    "it": "Guinea",
    "id": "Guinea",
    "ja": "ギニア",
    "th": "กินี",
    "pt": "Guiné"
  },
  "UG": {
    "en": "Uganda",
    "es": "Uganda",
    "fr": "Ouganda",
    "zh": "乌干达",
    "de": "Uganda",
    "it": "Uganda",
    "id": "Uganda",
    "ja": "ウガンダ",
    "th": "ยูกันดา",
    "pt": "Uganda"
  },
  "OM": {
    "en": "Oman",
    "es": "Omán",
    "fr": "Oman",
    "zh": "阿曼",
    "de": "Oman",
    "it": "Oman",
    "id": "Oman",
    "ja": "オマーン",
    "th": "โอมาน",
    "pt": "Omã"
  },
  "MN": {
    "en": "Mongolia",
    "es": "Mongolia",
    "fr": "Mongolie",
    "zh": "蒙古",
    "de": "Mongolei",
    "it": "Mongolia",
    "id": "Mongolia",
    "ja": "モンゴル",
    "th": "มองโกเลีย",
    "pt": "Mongólia"
  },
  "RS": {
    "en": "Serbia",
    "es": "Serbia",
    "fr": "Serbie",
    "zh": "塞尔维亚",
    "de": "Serbien",
    "it": "Serbia",
    "id": "Serbia",
    "ja": "セルビア",
    "th": "เซอร์เบีย",
    "pt": "Sérvia"
  },
  "NZ": {
    "en": "New Zealand",
    "es": "Nueva Zelanda",
    "fr": "Nouvelle-Zélande",
    "zh": "新西兰",
    "de": "Neuseeland",
    "it": "Nuova Zelanda",
    "id": "Selandia Baru",
    "ja": "ニュージーランド",
    "th": "นิวซีแลนด์",
    "pt": "Nova Zelândia"
  },
  "CZ": {
    "en": "Czechia",
    "es": "Chequia",
    "fr": "Tchéquie",
    "zh": "捷克",
    "de": "Tschechien",
    "it": "Cechia",
    "id": "Ceko",
    "ja": "チェコ",
    "th": "เช็ก",
    "pt": "Tchéquia"
  },
  "UY": {
    "en": "Uruguay",
    "es": "Uruguay",
    "fr": "Uruguay",
    "zh": "乌拉圭",
    "de": "Uruguay",
    "it": "Uruguay",
    "id": "Uruguay",
    "ja": "ウルグアイ",
    "th": "อุรุกวัย",
    "pt": "Uruguai"
  },
  "BG": {
    "en": "Bulgaria",
    "es": "Bulgaria",
    "fr": "Bulgarie",
    "zh": "保加利亚",
    "de": "Bulgarien",
    "it": "Bulgaria",
    "id": "Bulgaria",
    "ja": "ブルガリア",
    "th": "บัลแกเรีย",
    "pt": "Bulgária"
  },
  "HN": {
    "en": "Honduras",
    "es": "Honduras",
    "fr": "Honduras",
    "zh": "洪都拉斯",
    "de": "Honduras",
    "it": "Honduras",
    "id": "Honduras",
    "ja": "ホンジュラス",
    "th": "ฮอนดูรัส",
    "pt": "Honduras"
  },
  "MZ": {
    "en": "Mozambique",
    "es": "Mozambique",
    "fr": "Mozambique",
    "zh": "莫桑比克",
    "de": "Mosambik",
    "it": "Mozambico",
    "id": "Mozambik",
    "ja": "モザンビーク",
    "th": "โมซัมบิก",
    "pt": "Moçambique"
  },
  "QA": {
    "en": "Qatar",
    "es": "Catar",
    "fr": "Qatar",
    "zh": "卡塔尔",
    "de": "Katar",
    "it": "Qatar",
    "id": "Qatar",
    "ja": "カタール",
    "th": "กาตาร์",
    "pt": "Catar"
  },
  "SN": {
    "en": "Senegal",
    "es": "Senegal",
    "fr": "Sénégal",
    "zh": "塞内加尔",
    "de": "Senegal",
    "it": "Senegal",
    "id": "Senegal",
    "ja": "セネガル",
    "th": "เซเนกัล",
    "pt": "Senegal"
  },
  "RW": {
    "en": "Rwanda",
    "es": "Ruanda",
    "fr": "Rwanda",
    "zh": "卢旺达",
    "de": "Ruanda",
    "it": "Ruanda",
    "id": "Rwanda",
    "ja": "ルワンダ",
    "th": "รวันดา",
    "pt": "Ruanda"
  },
  "LY": {
    "en": "Libya",
    "es": "Libia",
    "fr": "Libye",
    "zh": "利比亚",
    "de": "Libyen",
    "it": "Libia",
    "id": "Libia",
    "ja": "リビア",
    "th": "ลิเบีย",
    "pt": "Líbia"
  },
  "GE": {
    "en": "Georgia",
    "es": "Georgia",
    "fr": "Géorgie",
    "zh": "格鲁吉亚",
    "de": "Georgien",
    "it": "Georgia",
    "id": "Georgia",
    "ja": "ジョージア",
    "th": "จอร์เจีย",
    "pt": "Geórgia"
  },
  "TD": {
    "en": "Chad",
    "es": "Chad",
    "fr": "Tchad",
    "zh": "乍得",
    "de": "Tschad",
    "it": "Ciad",
    "id": "Cad",
    "ja": "チャド",
    "th": "ชาด",
    "pt": "Chade"
  },
  "DK": {
    "en": "Denmark",
    "es": "Dinamarca",
    "fr": "Danemark",
    "zh": "丹麦",
    "de": "Dänemark",
    "it": "Danimarca",
    "id": "Denmark",
    "ja": "デンマーク",
    "th": "เดนมาร์ก",
    "pt": "Dinamarca"
  },
  "MR": {
    "en": "Mauritania",
    "es": "Mauritania",
    "fr": "Mauritanie",
    "zh": "毛里塔尼亚",
    "de": "Mauretanien",
    "it": "Mauritania",
    "id": "Mauritania",
    "ja": "モーリタニア",
    "th": "มอริเตเนีย",
    "pt": "Mauritânia"
  },
  "AM": {
    "en": "Armenia",
    "es": "Armenia",
    "fr": "Arménie",
    "zh": "亚美尼亚",
    "de": "Armenien",
    "it": "Armenia",
    "id": "Armenia",
    "ja": "アルメニア",
    "th": "อาร์เมเนีย",
    "pt": "Armênia"
  },
  "TN": {
    "en": "Tunisia",
    "es": "Túnez",
    "fr": "Tunisie",
    "zh": "突尼斯",
    "de": "Tunesien",
    "it": "Tunisia",
    "id": "Tunisia",
    "ja": "チュニジア",
    "th": "ตูนิเซีย",
    "pt": "Tunísia"
  },
  "KG": {
    "en": "Kyrgyzstan",
    "es": "Kirguistán",
    "fr": "Kirghizistan",
    "zh": "吉尔吉斯斯坦",
    "de": "Kirgisistan",
    "it": "Kirghizistan",
    "id": "Kirgistan",
    "ja": "キルギス",
    "th": "คีร์กีซสถาน",
    "pt": "Quirguistão"
  },
  "NI": {
    "en": "Nicaragua",
    "es": "Nicaragua",
    "fr": "Nicaragua",
    "zh": "尼加拉瓜",
    "de": "Nicaragua",
    "it": "Nicaragua",
    "id": "Nikaragua",
    "ja": "ニカラグア",
    "th": "นิการากัว",
    "pt": "Nicarágua"
  },
  "NE": {
    "en": "Niger",
    "es": "Níger",
    "fr": "Niger",
    "zh": "尼日尔",
    "de": "Niger",
    "it": "Niger",
    "id": "Niger",
    "ja": "ニジェール",
    "th": "ไนเจอร์",
    "pt": "Níger"
  },
  "LR": {
    "en": "Liberia",
    "es": "Liberia",
    "fr": "Libéria",
    "zh": "利比里亚",
    "de": "Liberia",
    "it": "Liberia",
    "id": "Liberia",
    "ja": "リベリア",
    "th": "ไลบีเรีย",
    "pt": "Libéria"
  },
  "HT": {
    "en": "Haiti",
    "es": "Haití",
    "fr": "Haïti",
    "zh": "海地",
    "de": "Haiti",
    "it": "Haiti",
    "id": "Haiti",
    "ja": "ハイチ",
    "th": "เฮติ",
    "pt": "Haiti"
  },
  "SE": {
    "en": "Sweden",
    "es": "Suecia",
    "fr": "Suède",
    "zh": "瑞典",
    "de": "Schweden",
    "it": "Svezia",
    "id": "Swedia",
    "ja": "スウェーデン",
    "th": "สวีเดน",
    "pt": "Suécia"
  },
  "NP": {
    "en": "Nepal",
    "es": "Nepal",
    "fr": "Népal",
    "zh": "尼泊尔",
    "de": "Nepal",
    "it": "Nepal",
    "id": "Nepal",
    "ja": "ネパール",
    "th": "เนปาล",
    "pt": "Nepal"
  },
  "ER": {
    "en": "Eritrea",
    "es": "Eritrea",
    "fr": "Érythrée",
    "zh": "厄立特里亚",
    "de": "Eritrea",
    "it": "Eritrea",
    "id": "Eritrea",
    "ja": "エリトリア",
    "th": "เอริเทรีย",
    "pt": "Eritreia"
  },
  "SL": {
    "en": "Sierra Leone",
    "es": "Sierra Leona",
    "fr": "Sierra Leone",
    "zh": "塞拉利昂",
    "de": "Sierra Leone",
    "it": "Sierra Leone",
    "id": "Sierra Leone",
    "ja": "シエラレオネ",
    "th": "เซียร์ราลีโอน",
    "pt": "Serra Leoa"
  },
  "LA": {
    "en": "Laos",
    "es": "Laos",
    "fr": "Laos",
    "zh": "老挝",
    "de": "Laos",
    "it": "Laos",
    "id": "Laos",
    "ja": "ラオス",
    "th": "ลาว",
    "pt": "Laos"
  },
  "IL": {
    "en": "Israel",
    "es": "Israel",
    "fr": "Israël",
    "zh": "以色列",
    "de": "Israel",
    "it": "Israele",
    "id": "Israel",
    "ja": "イスラエル",
    "th": "อิสราเอล",
    "pt": "Israel"
  },
  "CF": {
    "en": "Central African Republic",
    "es": "República Centroafricana",
    "fr": "République centrafricaine",
    "zh": "中非共和国",
    "de": "Zentralafrikanische Republik",
    "it": "Repubblica Centrafricana",
    "id": "Republik Afrika Tengah",
    "ja": "中央アフリカ共和国",
    "th": "สาธารณรัฐแอฟริกากลาง",
    "pt": "República Centro-Africana"
  },
  "PA": {
    "en": "Panama",
    "es": "Panamá",
    "fr": "Panama",
    "zh": "巴拿马",
    "de": "Panama",
    "it": "Panamá",
    "id": "Panama",
    "ja": "パナマ",
    "th": "ปานามา",
    "pt": "Panamá"
  },
  "NL": {
    "en": "Netherlands",
    "es": "Países Bajos",
    "fr": "Pays-Bas",
    "zh": "荷兰",
    "de": "Niederlande",
    "it": "Paesi Bassi",
    "id": "Belanda",
    "ja": "オランダ",
    "th": "เนเธอร์แลนด์",
    "pt": "Países Baixos"
  },
  "TG": {
    "en": "Togo",
    "es": "Togo",
    "fr": "Togo",
    "zh": "多哥",
    "de": "Togo",
    "it": "Togo",
    "id": "Togo",
    "ja": "トーゴ",
    "th": "โตโก",
    "pt": "Togo"
  },
  "GA": {
    "en": "Gabon",
    "es": "Gabón",
    "fr": "Gabon",
    "zh": "加蓬",
    "de": "Gabun",
    "it": "Gabon",
    "id": "Gabon",
    "ja": "ガボン",
    "th": "กาบอง",
    "pt": "Gabão"
  },
  "HR": {
    "en": "Croatia",
    "es": "Croacia",
    "fr": "Croatie",
    "zh": "克罗地亚",
    "de": "Kroatien",
    "it": "Croazia",
    "id": "Kroasia",
    "ja": "クロアチア",
    "th": "โครเอเชีย",
    "pt": "Croácia"
  },
  "TJ": {
    "en": "Tajikistan",
    "es": "Tayikistán",
    "fr": "Tadjikistan",
    "zh": "塔吉克斯坦",
    "de": "Tadschikistan",
    "it": "Tagikistan",
    "id": "Tajikistan",
    "ja": "タジキスタン",
    "th": "ทาจิกิสถาน",
    "pt": "Tadjiquistão"
  },
  "BJ": {
    "en": "Benin",
    "es": "Benín",
    "fr": "Bénin",
    "zh": "贝宁",
    "de": "Benin",
    "it": "Benin",
    "id": "Benin",
    "ja": "ベナン",
    "th": "เบนิน",
    "pt": "Benin"
  },
  "LK": {
    "en": "Sri Lanka",
    "es": "Sri Lanka",
    "fr": "Sri Lanka",
    "zh": "斯里兰卡",
    "de": "Sri Lanka",
    "it": "Sri Lanka",
    "id": "Sri Lanka",
    "ja": "スリランカ",
    "th": "ศรีลังกา",
    "pt": "Sri Lanka"
  },
  "NO": {
    "en": "Norway",
    "es": "Noruega",
    "fr": "Norvège",
    "zh": "挪威",
    "de": "Norwegen",
    "it": "Norvegia",
    "id": "Norwegia",
    "ja": "ノルウェー",
    "th": "นอร์เวย์",
    "pt": "Noruega"
  },
  "GR": {
    "en": "Greece",
    "es": "Grecia",
    "fr": "Grèce",
    "zh": "希腊",
    "de": "Griechenland",
    "it": "Grecia",
    "id": "Yunani",
    "ja": "ギリシャ",
    "th": "กรีซ",
    "pt": "Grécia"
  },
  "GL": {
    "en": "Greenland",
    "es": "Groenlandia",
    "fr": "Groenland",
    "zh": "格陵兰",
    "de": "Grönland",
    "it": "Groenlandia",
    "id": "Greenland",
    "ja": "グリーンランド",
    "th": "กรีนแลนด์",
    "pt": "Gronelândia"
  },
  "BI": {
    "en": "Burundi",
    "es": "Burundi",
    "fr": "Burundi",
    "zh": "布隆迪",
    "de": "Burundi",
    "it": "Burundi",
    "id": "Burundi",
    "ja": "ブルンジ",
    "th": "บุรุนดี",
    "pt": "Burundi"
  },
  "FI": {
    "en": "Finland",
    "es": "Finlandia",
    "fr": "Finlande",
    "zh": "芬兰",
    "de": "Finnland",
    "it": "Finlandia",
    "id": "Finlandia",
    "ja": "フィンランド",
    "th": "ฟินแลนด์",
    "pt": "Finlândia"
  },
  "MK": {
    "en": "North Macedonia",
    "es": "Macedonia del Norte",
    "fr": "Macédoine du Nord",
    "zh": "北马其顿",
    "de": "Nordmazedonien",
    "it": "Macedonia del Nord",
    "id": "Makedonia Utara",
    "ja": "北マケドニア",
    "th": "มาซิโดเนียเหนือ",
    "pt": "Macedônia do Norte"
  },
  "MD": {
    "en": "Moldova",
    "es": "Moldavia",
    "fr": "Moldavie",
    "zh": "摩尔多瓦",
    "de": "Republik Moldau",
    "it": "Moldavia",
    "id": "Moldova",
    "ja": "モルドバ",
    "th": "มอลโดวา",
    "pt": "Moldova"
  },
  "LV": {
    "en": "Latvia",
    "es": "Letonia",
    "fr": "Lettonie",
    "zh": "拉脱维亚",
    "de": "Lettland",
    "it": "Lettonia",
    "id": "Latvia",
    "ja": "ラトビア",
    "th": "ลัตเวีย",
    "pt": "Letônia"
  },
  "JM": {
    "en": "Jamaica",
    "es": "Jamaica",
    "fr": "Jamaïque",
    "zh": "牙买加",
    "de": "Jamaika",
    "it": "Giamaica",
    "id": "Jamaika",
    "ja": "ジャマイカ",
    "th": "จาเมกา",
    "pt": "Jamaica"
  },
  "LT": {
    "en": "Lithuania",
    "es": "Lituania",
    "fr": "Lituanie",
    "zh": "立陶宛",
    "de": "Litauen",
    "it": "Lituania",
    "id": "Lituania",
    "ja": "リトアニア",
    "th": "ลิทัวเนีย",
    "pt": "Lituânia"
  },
  "MO": {
    "en": "Macao",
    "es": "Macao",
    "fr": "Macao",
    "zh": "澳门",
    "de": "Macau",
    "it": "Macao",
    "id": "Makau",
    "ja": "マカオ",
    "th": "มาเก๊า",
    "pt": "Macau"
  },
  "SV": {
    "en": "El Salvador",
    "es": "El Salvador",
    "fr": "Salvador",
    "zh": "萨尔瓦多",
    "de": "El Salvador",
    "it": "El Salvador",
    "id": "El Salvador",
    "ja": "エルサルバドル",
    "th": "เอลซัลวาดอร์",
    "pt": "El Salvador"
  },
  "DJ": {
    "en": "Djibouti",
    "es": "Yibuti",
    "fr": "Djibouti",
    "zh": "吉布提",
    "de": "Dschibuti",
    "it": "Gibuti",
    "id": "Jibuti",
    "ja": "ジブチ",
    "th": "จิบูตี",
    "pt": "Djibuti"
  },
  "IE": {
    "en": "Ireland",
    "es": "Irlanda",
    "fr": "Irlande",
    "zh": "爱尔兰",
    "de": "Irland",
    "it": "Irlanda",
    "id": "Irlandia",
    "ja": "アイルランド",
    "th": "ไอร์แลนด์",
    "pt": "Irlanda"
  },
  "LB": {
    "en": "Lebanon",
    "es": "Líbano",
    "fr": "Liban",
    "zh": "黎巴嫩",
    "de": "Libanon",
    "it": "Libano",
    "id": "Lebanon",
    "ja": "レバノン",
    "th": "เลบานอน",
    "pt": "Líbano"
  },
  "PY": {
    "en": "Paraguay",
    "es": "Paraguay",
    "fr": "Paraguay",
    "zh": "巴拉圭",
    "de": "Paraguay",
    "it": "Paraguay",
    "id": "Paraguay",
    "ja": "パラグアイ",
    "th": "ปารากวัย",
    "pt": "Paraguai"
  },
  "PT": {
    "en": "Portugal",
    "es": "Portugal",
    "fr": "Portugal",
    "zh": "葡萄牙",
    "de": "Portugal",
    "it": "Portogallo",
    "id": "Portugal",
    "ja": "ポルトガル",
    "th": "โปรตุเกส",
    "pt": "Portugal"
  },
  "SK": {
    "en": "Slovakia",
    "es": "Eslovaquia",
    "fr": "Slovaquie",
    "zh": "斯洛伐克",
    "de": "Slowakei",
    "it": "Slovacchia",
    "id": "Slovakia",
    "ja": "スロバキア",
    "th": "สโลวะเกีย",
    "pt": "Eslováquia"
  },
  "EE": {
    "en": "Estonia",
    "es": "Estonia",
    "fr": "Estonie",
    "zh": "爱沙尼亚",
    "de": "Estland",
    "it": "Estonia",
    "id": "Estonia",
    "ja": "エストニア",
    "th": "เอสโตเนีย",
    "pt": "Estônia"
  },
  "CH": {
    "en": "Switzerland",
    "es": "Suiza",
    "fr": "Suisse",
    "zh": "瑞士",
    "de": "Schweiz",
    "it": "Svizzera",
    "id": "Swiss",
    "ja": "スイス",
    "th": "สวิตเซอร์แลนด์",
    "pt": "Suíça"
  },
  "AL": {
    "en": "Albania",
    "es": "Albania",
    "fr": "Albanie",
    "zh": "阿尔巴尼亚",
    "de": "Albanien",
    "it": "Albania",
    "id": "Albania",
    "ja": "アルバニア",
    "th": "แอลเบเนีย",
    "pt": "Albânia"
  },
  "TM": {
    "en": "Turkmenistan",
    "es": "Turkmenistán",
    "fr": "Turkménistan",
    "zh": "土库曼斯坦",
    "de": "Turkmenistan",
    "it": "Turkmenistan",
    "id": "Turkimenistan",
    "ja": "トルクメニスタン",
    "th": "เติร์กเมนิสถาน",
    "pt": "Turcomenistão"
  },
  "GW": {
    "en": "Guinea-Bissau",
    "es": "Guinea-Bisáu",
    "fr": "Guinée-Bissau",
    "zh": "几内亚比绍",
    "de": "Guinea-Bissau",
    "it": "Guinea-Bissau",
    "id": "Guinea-Bissau",
    "ja": "ギニアビサウ",
    "th": "กินี-บิสเซา",
    "pt": "Guiné-Bissau"
  },
  "SS": {
    "en": "South Sudan",
    "es": "Sudán del Sur",
    "fr": "Soudan du Sud",
    "zh": "南苏丹",
    "de": "Südsudan",
    "it": "Sud Sudan",
    "id": "Sudan Selatan",
    "ja": "南スーダン",
    "th": "ซูดานใต้",
    "pt": "Sudão do Sul"
  },
  "LS": {
    "en": "Lesotho",
    "es": "Lesoto",
    "fr": "Lesotho",
    "zh": "莱索托",
    "de": "Lesotho",
    "it": "Lesotho",
    "id": "Lesotho",
    "ja": "レソト",
    "th": "เลโซโท",
    "pt": "Lesoto"
  },
  "CY": {
    "en": "Cyprus",
    "es": "Chipre",
    "fr": "Chypre",
    "zh": "塞浦路斯",
    "de": "Zypern",
    "it": "Cipro",
    "id": "Siprus",
    "ja": "キプロス",
    "th": "ไซปรัส",
    "pt": "Chipre"
  },
  "PR": {
    "en": "Puerto Rico",
    "es": "Puerto Rico",
    "fr": "Porto Rico",
    "zh": "波多黎各",
    "de": "Puerto Rico",
    "it": "Portorico",
    "id": "Puerto Riko",
    "ja": "プエルトリコ",
    "th": "เปอร์โตริโก",
    "pt": "Porto Rico"
  },
  "NA": {
    "en": "Namibia",
    "es": "Namibia",
    "fr": "Namibie",
    "zh": "纳米比亚",
    "de": "Namibia",
    "it": "Namibia",
    "id": "Namibia",
    "ja": "ナミビア",
    "th": "นามิเบีย",
    "pt": "Namíbia"
  },
  "PG": {
    "en": "Papua New Guinea",
    "es": "Papúa Nueva Guinea",
    "fr": "Papouasie-Nouvelle-Guinée",
    "zh": "巴布亚新几内亚",
    "de": "Papua-Neuguinea",
    "it": "Papua Nuova Guinea",
    "id": "Papua Nugini",
    "ja": "パプアニューギニア",
    "th": "ปาปัวนิวกินี",
    "pt": "Papua-Nova Guiné"
  },
  "MU": {
    "en": "Mauritius",
    "es": "Mauricio",
    "fr": "Maurice",
    "zh": "毛里求斯",
    "de": "Mauritius",
    "it": "Mauritius",
    "id": "Mauritius",
    "ja": "モーリシャス",
    "th": "มอริเชียส",
    "pt": "Maurício"
  },
  "CR": {
    "en": "Costa Rica",
    "es": "Costa Rica",
    "fr": "Costa Rica",
    "zh": "哥斯达黎加",
    "de": "Costa Rica",
    "it": "Costa Rica",
    "id": "Kosta Rika",
    "ja": "コスタリカ",
    "th": "คอสตาริกา",
    "pt": "Costa Rica"
  },
  "SI": {
    "en": "Slovenia",
    "es": "Eslovenia",
    "fr": "Slovénie",
    "zh": "斯洛文尼亚",
    "de": "Slowenien",
    "it": "Slovenia",
    "id": "Slovenia",
    "ja": "スロベニア",
    "th": "สโลวีเนีย",
    "pt": "Eslovênia"
  },
  "BA": {
    "en": "Bosnia And Herzegovina",
    "es": "Bosnia y Herzegovina",
    "fr": "Bosnie-Herzégovine",
    "zh": "波斯尼亚和黑塞哥维那",
    "de": "Bosnien und Herzegowina",
    "it": "Bosnia ed Erzegovina",
    "id": "Bosnia dan Herzegovina",
    "ja": "ボスニア・ヘルツェゴビナ",
    "th": "บอสเนียและเฮอร์เซโกวีนา",
    "pt": "Bósnia e Herzegovina"
  },
  "BS": {
    "en": "Bahamas",
    "es": "Bahamas",
    "fr": "Bahamas",
    "zh": "巴哈马",
    "de": "Bahamas",
    "it": "Bahamas",
    "id": "Bahama",
    "ja": "バハマ",
    "th": "บาฮามาส",
    "pt": "Bahamas"
  },
  "MQ": {
    "en": "Martinique",
    "es": "Martinica",
    "fr": "Martinique",
    "zh": "马提尼克",
    "de": "Martinique",
    "it": "Martinica",
    "id": "Martinik",
    "ja": "マルティニーク",
    "th": "มาร์ตินีก",
    "pt": "Martinica"
  },
  "GQ": {
    "en": "Equatorial Guinea",
    "es": "Guinea Ecuatorial",
    "fr": "Guinée équatoriale",
    "zh": "赤道几内亚",
    "de": "Äquatorialguinea",
    "it": "Guinea Equatoriale",
    "id": "Guinea Ekuatorial",
    "ja": "赤道ギニア",
    "th": "อิเควทอเรียลกินี",
    "pt": "Guiné Equatorial"
  },
  "BW": {
    "en": "Botswana",
    "es": "Botsuana",
    "fr": "Botswana",
    "zh": "博茨瓦纳",
    "de": "Botsuana",
    "it": "Botswana",
    "id": "Botswana",
    "ja": "ボツワナ",
    "th": "บอตสวานา",
    "pt": "Botsuana"
  },
  "SR": {
    "en": "Suriname",
    "es": "Surinam",
    "fr": "Suriname",
    "zh": "苏里南",
    "de": "Suriname",
    "it": "Suriname",
    "id": "Suriname",
    "ja": "スリナム",
    "th": "ซูรินาเม",
    "pt": "Suriname"
  },
  "TL": {
    "en": "Timor-Leste",
    "es": "Timor-Leste",
    "fr": "Timor oriental",
    "zh": "东帝汶",
    "de": "Timor-Leste",
    "it": "Timor Est",
    "id": "Timor Leste",
    "ja": "東ティモール",
    "th": "ติมอร์-เลสเต",
    "pt": "Timor-Leste"
  },
  "GY": {
    "en": "Guyana",
    "es": "Guyana",
    "fr": "Guyana",
    "zh": "圭亚那",
    "de": "Guyana",
    "it": "Guyana",
    "id": "Guyana",
    "ja": "ガイアナ",
    "th": "กายอานา",
    "pt": "Guiana"
  },
  "GM": {
    "en": "Gambia",
    "es": "Gambia",
    "fr": "Gambie",
    "zh": "冈比亚",
    "de": "Gambia",
    "it": "Gambia",
    "id": "Gambia",
    "ja": "ガンビア",
    "th": "แกมเบีย",
    "pt": "Gâmbia"
  },
  "RE": {
    "en": "Réunion",
    "es": "Reunión",
    "fr": "La Réunion",
    "zh": "留尼汪",
    "de": "Réunion",
    "it": "Riunione",
    "id": "Réunion",
    "ja": "レユニオン",
    "th": "เรอูนียง",
    "pt": "Reunião"
  },
  "GI": {
    "en": "Gibraltar",
    "es": "Gibraltar",
    "fr": "Gibraltar",
    "zh": "直布罗陀",
    "de": "Gibraltar",
    "it": "Gibilterra",
    "id": "Gibraltar",
    "ja": "ジブラルタル",
    "th": "ยิบรอลตาร์",
    "pt": "Gibraltar"
  },
  "ME": {
    "en": "Montenegro",
    "es": "Montenegro",
    "fr": "Monténégro",
    "zh": "黑山",
    "de": "Montenegro",
    "it": "Montenegro",
    "id": "Montenegro",
    "ja": "モンテネグロ",
    "th": "มอนเตเนโกร",
    "pt": "Montenegro"
  },
  "BH": {
    "en": "Bahrain",
    "es": "Baréin",
    "fr": "Bahreïn",
    "zh": "巴林",
    "de": "Bahrain",
    "it": "Bahrein",
    "id": "Bahrain",
    "ja": "バーレーン",
    "th": "บาห์เรน",
    "pt": "Bahrein"
  },
  "CW": {
    "en": "Curaçao",
    "es": "Curazao",
    "fr": "Curaçao",
    "zh": "库拉索",
    "de": "Curaçao",
    "it": "Curaçao",
    "id": "Curaçao",
    "ja": "キュラソー",
    "th": "คูราเซา",
    "pt": "Curaçao"
  },
  "GP": {
    "en": "Guadeloupe",
    "es": "Guadalupe",
    "fr": "Guadeloupe",
    "zh": "瓜德罗普",
    "de": "Guadeloupe",
    "it": "Guadalupa",
    "id": "Guadeloupe",
    "ja": "グアドループ",
    "th": "กวาเดอลูป",
    "pt": "Guadalupe"
  },
  "MV": {
    "en": "Maldives",
    "es": "Maldivas",
    "fr": "Maldives",
    "zh": "马尔代夫",
    "de": "Malediven",
    "it": "Maldive",
    "id": "Maladewa",
    "ja": "モルディブ",
    "th": "มัลดีฟส์",
    "pt": "Maldivas"
  },
  "MT": {
    "en": "Malta",
    "es": "Malta",
    "fr": "Malte",
    "zh": "马耳他",
    "de": "Malta",
    "it": "Malta",
    "id": "Malta",
    "ja": "マルタ",
    "th": "มอลตา",
    "pt": "Malta"
  },
  "PF": {
    "en": "French Polynesia",
    "es": "Polinesia Francesa",
    "fr": "Polynésie française",
    "zh": "法属波利尼西亚",
    "de": "Französisch-Polynesien",
    "it": "Polinesia francese",
    "id": "Polinesia Prancis",
    "ja": "仏領ポリネシア",
    "th": "เฟรนช์โปลินีเซีย",
    "pt": "Polinésia Francesa"
  },
  "IS": {
    "en": "Iceland",
    "es": "Islandia",
    "fr": "Islande",
    "zh": "冰岛",
    "de": "Island",
    "it": "Islanda",
    "id": "Islandia",
    "ja": "アイスランド",
    "th": "ไอซ์แลนด์",
    "pt": "Islândia"
  },
  "LU": {
    "en": "Luxembourg",
    "es": "Luxemburgo",
    "fr": "Luxembourg",
    "zh": "卢森堡",
    "de": "Luxemburg",
    "it": "Lussemburgo",
    "id": "Luksemburg",
    "ja": "ルクセンブルク",
    "th": "ลักเซมเบิร์ก",
    "pt": "Luxemburgo"
  },
  "CV": {
    "en": "Cape Verde",
    "es": "Cabo Verde",
    "fr": "Cap-Vert",
    "zh": "佛得角",
    "de": "Cabo Verde",
    "it": "Capo Verde",
    "id": "Tanjung Verde",
    "ja": "カーボベルデ",
    "th": "เคปเวิร์ด",
    "pt": "Cabo Verde"
  },
  "GU": {
    "en": "Guam",
    "es": "Guam",
    "fr": "Guam",
    "zh": "关岛",
    "de": "Guam",
    "it": "Guam",
    "id": "Guam",
    "ja": "グアム",
    "th": "กวม",
    "pt": "Guam"
  },
  "KM": {
    "en": "Comoros",
    "es": "Comoras",
    "fr": "Comores",
    "zh": "科摩罗",
    "de": "Komoren",
    "it": "Comore",
    "id": "Komoro",
    "ja": "コモロ",
    "th": "คอโมโรส",
    "pt": "Comores"
  },
  "SZ": {
    "en": "Eswatini",
    "es": "Esuatini",
    "fr": "Eswatini",
    "zh": "斯威士兰",
    "de": "Eswatini",
    "it": "Swaziland",
    "id": "eSwatini",
    "ja": "エスワティニ",
    "th": "เอสวาตีนี",
    "pt": "Essuatíni"
  },
  "BB": {
    "en": "Barbados",
    "es": "Barbados",
    "fr": "Barbade",
    "zh": "巴巴多斯",
    "de": "Barbados",
    "it": "Barbados",
    "id": "Barbados",
    "ja": "バルバドス",
    "th": "บาร์เบโดส",
    "pt": "Barbados"
  },
  "BT": {
    "en": "Bhutan",
    "es": "Bután",
    "fr": "Bhoutan",
    "zh": "不丹",
    "de": "Bhutan",
    "it": "Bhutan",
    "id": "Bhutan",
    "ja": "ブータン",
    "th": "ภูฏาน",
    "pt": "Butão"
  },
  "XK": {
    "en": "Kosovo",
    "es": "Kosovo",
    "fr": "Kosovo",
    "zh": "科索沃",
    "de": "Kosovo",
    "it": "Kosovo",
    "id": "Kosovo",
    "ja": "コソボ",
    "th": "โคโซโว",
    "pt": "Kosovo"
  },
  "FQ": {
    "en": "French Southern and Antarctic Lands",
    "es": "Tierras Australes y Antárticas Francesas",
    "fr": "Terres australes et antarctiques françaises",
    "zh": "法属南部和南极领地",
    "de": "Französische Süd- und Antarktisgebiete",
    "it": "Terre australi e antartiche francesi",
    "id": "Kepulauan Perancis Selatan dan Antarktika",
    "ja": "フランス領南方・南極地域",
    "th": "ดินแดนทางใต้และแอนตาร์กติกของฝรั่งเศส",
    "pt": "Terras Austrais e Antárticas Francesas"
  },
  "BZ": {
    "en": "Belize",
    "es": "Belice",
    "fr": "Belize",
    "zh": "伯利兹",
    "de": "Belize",
    "it": "Belize",
    "id": "Belize",
    "ja": "ベリーズ",
    "th": "เบลีซ",
    "pt": "Belize"
  },
  "BM": {
    "en": "Bermuda",
    "es": "Bermudas",
    "fr": "Bermudes",
    "zh": "百慕大",
    "de": "Bermuda",
    "it": "Bermuda",
    "id": "Bermuda",
    "ja": "バミューダ",
    "th": "เบอร์มิวดา",
    "pt": "Bermudas"
  },
  "BN": {
    "en": "Brunei",
    "es": "Brunéi",
    "fr": "Brunei",
    "zh": "文莱",
    "de": "Brunei",
    "it": "Brunei",
    "id": "Brunei",
    "ja": "ブルネイ",
    "th": "บรูไน",
    "pt": "Brunei"
  },
  "FJ": {
    "en": "Fiji",
    "es": "Fiyi",
    "fr": "Fidji",
    "zh": "斐济",
    "de": "Fidschi",
    "it": "Figi",
    "id": "Fiji",
    "ja": "フィジー",
    "th": "ฟิจิ",
    "pt": "Fiji"
  },
  "FK": {
    "en": "Falkland Islands",
    "es": "Islas Malvinas",
    "fr": "Îles Malouines",
    "zh": "福克兰群岛",
    "de": "Falklandinseln",
    "it": "Isole Falkland",
    "id": "Kepulauan Falkland",
    "ja": "フォークランド諸島",
    "th": "หมู่เกาะฟอล์กแลนด์",
    "pt": "Ilhas Malvinas"
  },
  "GF": {
    "en": "French Guiana",
    "es": "Guayana Francesa",
    "fr": "Guyane française",
    "zh": "法属圭亚那",
    "de": "Französisch-Guayana",
    "it": "Guiana francese",
    "id": "Guyana Prancis",
    "ja": "仏領ギアナ",
    "th": "เฟรนช์เกียนา",
    "pt": "Guiana Francesa"
  },
  "NC": {
    "en": "New Caledonia",
    "es": "Nueva Caledonia",
    "fr": "Nouvelle-Calédonie",
    "zh": "新喀里多尼亚",
    "de": "Neukaledonien",
    "it": "Nuova Caledonia",
    "id": "Kaledonia Baru",
    "ja": "ニューカレドニア",
    "th": "นิวแคลิโดเนีย",
    "pt": "Nova Caledônia"
  },
  "EH": {
    "en": "Western Sahara",
    "es": "Sahara Occidental",
    "fr": "Sahara Occidental",
    "zh": "西撒哈拉",
    "de": "Westsahara",
    "it": "Sahara Occidentale",
    "id": "Sahara Barat",
    "ja": "西サハラ",
    "th": "ซาฮาราตะวันตก",
    "pt": "Saara Ocidental"
  },
  "SB": {
    "en": "Solomon Islands",
    "es": "Islas Salomón",
    "fr": "Îles Salomon",
    "zh": "所罗门群岛",
    "de": "Salomonen",
    "it": "Isole Salomone",
    "id": "Kepulauan Solomon",
    "ja": "ソロモン諸島",
    "th": "หมู่เกาะโซโลมอน",
    "pt": "Ilhas Salomão"
  },
  "TT": {
    "en": "Trinidad and Tobago",
    "es": "Trinidad y Tobago",
    "fr": "Trinité-et-Tobago",
    "zh": "特立尼达和多巴哥",
    "de": "Trinidad und Tobago",
    "it": "Trinidad e Tobago",
    "id": "Trinidad dan Tobago",
    "ja": "トリニダード・トバゴ",
    "th": "ตรินิแดดและโตเบโก",
    "pt": "Trinidad e Tobago"
  },
  "VU": {
    "en": "Vanuatu",
    "es": "Vanuatu",
    "fr": "Vanuatu",
    "zh": "瓦努阿图",
    "de": "Vanuatu",
    "it": "Vanuatu",
    "id": "Vanuatu",
    "ja": "バヌアツ",
    "th": "วานูอาตู",
    "pt": "Vanuatu"
  },
  "PS": {
    "en": "Palestine",
    "es": "Palestina",
    "fr": "Palestine",
    "zh": "巴勒斯坦",
    "de": "Palästina",
    "it": "Palestina",
    "id": "Palestina",
    "ja": "パレスチナ",
    "th": "ปาเลสไตน์",
    "pt": "Palestina"
  },
  "LC": {
    "en": "Saint Lucia",
    "es": "Santa Lucía",
    "fr": "Sainte-Lucie",
    "zh": "圣卢西亚",
    "de": "St. Lucia",
    "it": "Santa Lucia",
    "id": "Saint Lucia",
    "ja": "セントルシア",
    "th": "เซนต์ลูเซีย",
    "pt": "Santa Lúcia"
  },
  "ST": {
    "en": "São Tomé and Príncipe",
    "es": "Santo Tomé y Príncipe",
    "fr": "Sao Tomé-et-Principe",
    "zh": "圣多美和普林西比",
    "de": "São Tomé und Príncipe",
    "it": "São Tomé e Príncipe",
    "id": "São Tomé dan Príncipe",
    "ja": "サントメ・プリンシペ",
    "th": "เซาตูเมและปรินซิปี",
    "pt": "São Tomé e Príncipe"
  },
  "WS": {
    "en": "Samoa",
    "es": "Samoa",
    "fr": "Samoa",
    "zh": "萨摩亚",
    "de": "Samoa",
    "it": "Samoa",
    "id": "Samoa",
    "ja": "サモア",
    "th": "ซามัว",
    "pt": "Samoa"
  },
  "MC": {
    "en": "Monaco",
    "es": "Mónaco",
    "fr": "Monaco",
    "zh": "摩纳哥",
    "de": "Monaco",
    "it": "Monaco",
    "id": "Monako",
    "ja": "モナコ",
    "th": "โมนาโก",
    "pt": "Mônaco"
  },
  "AW": {
    "en": "Aruba",
    "es": "Aruba",
    "fr": "Aruba",
    "zh": "阿鲁巴",
    "de": "Aruba",
    "it": "Aruba",
    "id": "Aruba",
    "ja": "アルバ",
    "th": "อารูบา",
    "pt": "Aruba"
  },
  "JE": {
    "en": "Jersey",
    "es": "Jersey",
    "fr": "Jersey",
    "zh": "泽西岛",
    "de": "Jersey",
    "it": "Jersey",
    "id": "Jersey",
    "ja": "ジャージー",
    "th": "เจอร์ซีย์",
    "pt": "Jersey"
  },
  "YT": {
    "en": "Mayotte",
    "es": "Mayotte",
    "fr": "Mayotte",
    "zh": "马约特",
    "de": "Mayotte",
    "it": "Mayotte",
    "id": "Mayotte",
    "ja": "マヨット",
    "th": "มายอต",
    "pt": "Mayotte"
  },
  "MH": {
    "en": "Marshall Islands",
    "es": "Islas Marshall",
    "fr": "Îles Marshall",
    "zh": "马绍尔群岛",
    "de": "Marshallinseln",
    "it": "Isole Marshall",
    "id": "Kepulauan Marshall",
    "ja": "マーシャル諸島",
    "th": "หมู่เกาะมาร์แชลล์",
    "pt": "Ilhas Marshall"
  },
  "KI": {
    "en": "Kiribati",
    "es": "Kiribati",
    "fr": "Kiribati",
    "zh": "基里巴斯",
    "de": "Kiribati",
    "it": "Kiribati",
    "id": "Kiribati",
    "ja": "キリバス",
    "th": "คิริบาส",
    "pt": "Kiribati"
  },
  "IM": {
    "en": "Isle of Man",
    "es": "Isla de Man",
    "fr": "Île de Man",
    "zh": "马恩岛",
    "de": "Isle of Man",
    "it": "Isola di Man",
    "id": "Pulau Man",
    "ja": "マン島",
    "th": "เกาะแมน",
    "pt": "Ilha de Man"
  },
  "KY": {
    "en": "Cayman Islands",
    "es": "Islas Caimán",
    "fr": "Îles Caïmans",
    "zh": "开曼群岛",
    "de": "Kaimaninseln",
    "it": "Isole Cayman",
    "id": "Kepulauan Cayman",
    "ja": "ケイマン諸島",
    "th": "หมู่เกาะเคย์แมน",
    "pt": "Ilhas Cayman"
  },
  "SC": {
    "en": "Seychelles",
    "es": "Seychelles",
    "fr": "Seychelles",
    "zh": "塞舌尔",
    "de": "Seychellen",
    "it": "Seychelles",
    "id": "Seychelles",
    "ja": "セーシェル",
    "th": "เซเชลส์",
    "pt": "Seicheles"
  },
  "VC": {
    "en": "Saint Vincent and the Grenadines",
    "es": "San Vicente y las Granadinas",
    "fr": "Saint-Vincent-et-les-Grenadines",
    "zh": "圣文森特和格林纳丁斯",
    "de": "St. Vincent und die Grenadinen",
    "it": "Saint Vincent e Grenadine",
    "id": "Saint Vincent dan Grenadine",
    "ja": "セントビンセントおよびグレナディーン諸島",
    "th": "เซนต์วินเซนต์และเกรนาดีนส์",
    "pt": "São Vicente e Granadinas"
  },
  "TO": {
    "en": "Tonga",
    "es": "Tonga",
    "fr": "Tonga",
    "zh": "汤加",
    "de": "Tonga",
    "it": "Tonga",
    "id": "Tonga",
    "ja": "トンガ",
    "th": "ตองกา",
    "pt": "Tonga"
  },
  "AD": {
    "en": "Andorra",
    "es": "Andorra",
    "fr": "Andorre",
    "zh": "安道尔",
    "de": "Andorra",
    "it": "Andorra",
    "id": "Andorra",
    "ja": "アンドラ",
    "th": "อันดอร์รา",
    "pt": "Andorra"
  },
  "AG": {
    "en": "Antigua and Barbuda",
    "es": "Antigua y Barbuda",
    "fr": "Antigua-et-Barbuda",
    "zh": "安提瓜和巴布达",
    "de": "Antigua und Barbuda",
    "it": "Antigua e Barbuda",
    "id": "Antigua dan Barbuda",
    "ja": "アンティグア・バーブーダ",
    "th": "แอนติกาและบาร์บูดา",
    "pt": "Antígua e Barbuda"
  },
  "DM": {
    "en": "Dominica",
    "es": "Dominica",
    "fr": "Dominique",
    "zh": "多米尼克",
    "de": "Dominica",
    "it": "Dominica",
    "id": "Dominika",
    "ja": "ドミニカ国",
    "th": "โดมินิกา",
    "pt": "Dominica"
  },
  "FO": {
    "en": "Faroe Islands",
    "es": "Islas Feroe",
    "fr": "Îles Féroé",
    "zh": "法罗群岛",
    "de": "Färöer-Inseln",
    "it": "Isole Faroe",
    "id": "Kepulauan Faroe",
    "ja": "フェロー諸島",
    "th": "หมู่เกาะแฟโร",
    "pt": "Ilhas Faroé"
  },
  "KN": {
    "en": "Saint Kitts and Nevis",
    "es": "San Cristóbal y Nieves",
    "fr": "Saint-Kitts-et-Nevis",
    "zh": "圣基茨和尼维斯",
    "de": "St. Kitts und Nevis",
    "it": "Saint Kitts e Nevis",
    "id": "Saint Kitts dan Nevis",
    "ja": "セントクリストファー・ネーヴィス",
    "th": "เซนต์คิตส์และเนวิส",
    "pt": "São Cristóvão e Nevis"
  },
  "AS": {
    "en": "American Samoa",
    "es": "Samoa Americana",
    "fr": "Samoa américaines",
    "zh": "美属萨摩亚",
    "de": "Amerikanisch-Samoa",
    "it": "Samoa americane",
    "id": "Samoa Amerika",
    "ja": "アメリカ領サモア",
    "th": "อเมริกันซามัว",
    "pt": "Samoa Americana"
  },
  "FM": {
    "en": "Micronesia",
    "es": "Micronesia",
    "fr": "Micronésie",
    "zh": "密克罗尼西亚",
    "de": "Mikronesien",
    "it": "Micronesia",
    "id": "Mikronesia",
    "ja": "ミクロネシア",
    "th": "ไมโครนีเซีย",
    "pt": "Micronésia"
  },
  "TV": {
    "en": "Tuvalu",
    "es": "Tuvalu",
    "fr": "Tuvalu",
    "zh": "图瓦卢",
    "de": "Tuvalu",
    "it": "Tuvalu",
    "id": "Tuvalu",
    "ja": "ツバル",
    "th": "ตูวาลู",
    "pt": "Tuvalu"
  },
  "LI": {
    "en": "Liechtenstein",
    "es": "Liechtenstein",
    "fr": "Liechtenstein",
    "zh": "列支敦士登",
    "de": "Liechtenstein",
    "it": "Liechtenstein",
    "id": "Liechtenstein",
    "ja": "リヒテンシュタイン",
    "th": "ลิกเตนสไตน์",
    "pt": "Liechtenstein"
  },
  "TC": {
    "en": "Turks and Caicos Islands",
    "es": "Islas Turcas y Caicos",
    "fr": "Îles Turques-et-Caïques",
    "zh": "特克斯和凯科斯群岛",
    "de": "Turks- und Caicosinseln",
    "it": "Isole Turks e Caicos",
    "id": "Kepulauan Turks dan Caicos",
    "ja": "タークス・カイコス諸島",
    "th": "หมู่เกาะเติกส์และเคคอส",
    "pt": "Ilhas Turcas e Caicos"
  },
  "CK": {
    "en": "Cook Islands",
    "es": "Islas Cook",
    "fr": "Îles Cook",
    "zh": "库克群岛",
    "de": "Cookinseln",
    "it": "Isole Cook",
    "id": "Kepulauan Cook",
    "ja": "クック諸島",
    "th": "หมู่เกาะคุก",
    "pt": "Ilhas Cook"
  },
  "XG": {
    "en": "External Gabon",
    "es": "Gabón Exterior",
    "fr": "Gabon Extérieur",
    "zh": "加蓬外部",
    "de": "Extern Gabun",
    "it": "Gabon Esterno",
    "id": "Gabon Eksternal",
    "ja": "外部ガボン",
    "th": "กาบองภายนอก",
    "pt": "Gabão Externo"
  },
  "GD": {
    "en": "Grenada",
    "es": "Granada",
    "fr": "Grenade",
    "zh": "格林纳达",
    "de": "Grenada",
    "it": "Grenada",
    "id": "Grenada",
    "ja": "グレナダ",
    "th": "เกรเนดา",
    "pt": "Granada"
  },
  "SM": {
    "en": "San Marino",
    "es": "San Marino",
    "fr": "Saint-Marin",
    "zh": "圣马力诺",
    "de": "San Marino",
    "it": "San Marino",
    "id": "San Marino",
    "ja": "サンマリノ",
    "th": "ซานมารีโน",
    "pt": "San Marino"
  },
  "MP": {
    "en": "Northern Mariana Islands",
    "es": "Islas Marianas del Norte",
    "fr": "Îles Mariannes du Nord",
    "zh": "北马里亚纳群岛",
    "de": "Nördliche Marianen",
    "it": "Isole Marianne Settentrionali",
    "id": "Kepulauan Mariana Utara",
    "ja": "北マリアナ諸島",
    "th": "หมู่เกาะมาเรียนาเหนือ",
    "pt": "Ilhas Marianas do Norte"
  },
  "NU": {
    "en": "Niue",
    "es": "Niue",
    "fr": "Niue",
    "zh": "纽埃",
    "de": "Niue",
    "it": "Niue",
    "id": "Niue",
    "ja": "ニウエ",
    "th": "นีอูเอ",
    "pt": "Niue"
  },
  "XW": {
    "en": "External Wallis",
    "es": "Wallis Exterior",
    "fr": "Wallis Extérieur",
    "zh": "外部瓦利斯",
    "de": "Extern Wallis",
    "it": "Wallis Esterno",
    "id": "Wallis Eksternal",
    "ja": "外部ワリス",
    "th": "วาลลิสภายนอก",
    "pt": "Wallis Externo"
  },
  "MF": {
    "en": "Saint Martin",
    "es": "San Martín",
    "fr": "Saint-Martin",
    "zh": "圣马丁",
    "de": "Saint-Martin",
    "it": "Saint Martin",
    "id": "Saint Martin",
    "ja": "サン・マルタン",
    "th": "แซงต์มาร์แตง",
    "pt": "São Martinho"
  },
  "SX": {
    "en": "Sint Maarten",
    "es": "Sint Maarten",
    "fr": "Saint-Martin",
    "zh": "荷属圣马丁",
    "de": "Sint Maarten",
    "it": "Sint Maarten",
    "id": "Sint Maarten",
    "ja": "シント・マールテン",
    "th": "ซินต์มาร์เติน",
    "pt": "São Martinho"
  },
  "NR": {
    "en": "Nauru",
    "es": "Nauru",
    "fr": "Nauru",
    "zh": "瑙鲁",
    "de": "Nauru",
    "it": "Nauru",
    "id": "Nauru",
    "ja": "ナウル",
    "th": "นาอูรู",
    "pt": "Nauru"
  },
  "BL": {
    "en": "Saint Barthélemy",
    "es": "San Bartolomé",
    "fr": "Saint-Barthélemy",
    "zh": "圣巴泰勒米",
    "de": "St. Barthélemy",
    "it": "Saint Barthélemy",
    "id": "Saint Barthélemy",
    "ja": "サン・バルテルミー",
    "th": "แซ็งบาร์เตเลมี",
    "pt": "São Bartolomeu"
  },
  "VG": {
    "en": "British Virgin Islands",
    "es": "Islas Vírgenes Británicas",
    "fr": "Îles Vierges britanniques",
    "zh": "英属维尔京群岛",
    "de": "Britische Jungferninseln",
    "it": "Isole Vergini Britanniche",
    "id": "Kepulauan Virgin Britania",
    "ja": "イギリス領バージン諸島",
    "th": "หมู่เกาะบริติชเวอร์จิน",
    "pt": "Ilhas Virgens Britânicas"
  },
  "PM": {
    "en": "Saint Pierre and Miquelon",
    "es": "San Pedro y Miquelón",
    "fr": "Saint-Pierre-et-Miquelon",
    "zh": "圣皮埃尔和密克隆群岛",
    "de": "St. Pierre und Miquelon",
    "it": "Saint Pierre e Miquelon",
    "id": "Saint Pierre dan Miquelon",
    "ja": "サンピエール島・ミクロン島",
    "th": "แซ็งปีแยร์และมีเกอลง",
    "pt": "São Pedro e Miquelon"
  },
  "AI": {
    "en": "Anguilla",
    "es": "Anguila",
    "fr": "Anguilla",
    "zh": "安圭拉",
    "de": "Anguilla",
    "it": "Anguilla",
    "id": "Anguilla",
    "ja": "アンギラ",
    "th": "แองกวิลลา",
    "pt": "Anguila"
  },
  "WF": {
    "en": "Wallis and Futuna",
    "es": "Wallis y Futuna",
    "fr": "Wallis-et-Futuna",
    "zh": "瓦利斯和富图纳",
    "de": "Wallis und Futuna",
    "it": "Wallis e Futuna",
    "id": "Wallis dan Futuna",
    "ja": "ウォリス・フツナ",
    "th": "วาลลิสและฟุตูนา",
    "pt": "Wallis e Futuna"
  },
  "NF": {
    "en": "Norfolk Island",
    "es": "Isla Norfolk",
    "fr": "Île Norfolk",
    "zh": "诺福克岛",
    "de": "Norfolkinsel",
    "it": "Isola Norfolk",
    "id": "Pulau Norfolk",
    "ja": "ノーフォーク島",
    "th": "เกาะนอร์ฟอล์ก",
    "pt": "Ilha Norfolk"
  },
  "XR": {
    "en": "Reunion Island",
    "es": "Isla Reunión",
    "fr": "Île de la Réunion",
    "zh": "留尼汪岛",
    "de": "Insel Réunion",
    "it": "Isola di Riunione",
    "id": "Pulau Reunion",
    "ja": "レユニオン島",
    "th": "เกาะเรอูนียง",
    "pt": "Ilha da Reunião"
  },
  "PN": {
    "en": "Pitcairn Islands",
    "es": "Islas Pitcairn",
    "fr": "Îles Pitcairn",
    "zh": "皮特凯恩群岛",
    "de": "Pitcairninseln",
    "it": "Isole Pitcairn",
    "id": "Kepulauan Pitcairn",
    "ja": "ピトケアン諸島",
    "th": "หมู่เกาะพิตแคร์น",
    "pt": "Ilhas Pitcairn"
  },
  "CX": {
    "en": "Christmas Island",
    "es": "Isla de Navidad",
    "fr": "Île Christmas",
    "zh": "圣诞岛",
    "de": "Weihnachtsinsel",
    "it": "Isola di Natale",
    "id": "Pulau Christmas",
    "ja": "クリスマス島",
    "th": "เกาะคริสต์มาส",
    "pt": "Ilha Christmas"
  },
  "VA": {
    "en": "Vatican City",
    "es": "Ciudad del Vaticano",
    "fr": "Cité du Vatican",
    "zh": "梵蒂冈",
    "de": "Vatikanstadt",
    "it": "Città del Vaticano",
    "id": "Kota Vatikan",
    "ja": "バチカン市国",
    "th": "นครวาติกัน",
    "pt": "Cidade do Vaticano"
  },
  "SH": {
    "en": "Saint Helena",
    "es": "Santa Elena",
    "fr": "Sainte-Hélène",
    "zh": "圣赫勒拿",
    "de": "St. Helena",
    "it": "Sant'Elena",
    "id": "Saint Helena",
    "ja": "セントヘレナ",
    "th": "เซนต์เฮเลนา",
    "pt": "Santa Helena"
  },
  "MS": {
    "en": "Montserrat",
    "es": "Montserrat",
    "fr": "Montserrat",
    "zh": "蒙特塞拉特",
    "de": "Montserrat",
    "it": "Montserrat",
    "id": "Montserrat",
    "ja": "モントセラト",
    "th": "มอนต์เซอร์รัต",
    "pt": "Montserrat"
  },
  "PW": {
    "en": "Palau",
    "es": "Palaos",
    "fr": "Palaos",
    "zh": "帕劳",
    "de": "Palau",
    "it": "Palau",
    "id": "Palau",
    "ja": "パラオ",
    "th": "ปาเลา",
    "pt": "Palau"
  },
  "VI": {
    "en": "U.S. Virgin Islands",
    "es": "Islas Vírgenes de los Estados Unidos",
    "fr": "Îles Vierges américaines",
    "zh": "美属维尔京群岛",
    "de": "Amerikanische Jungferninseln",
    "it": "Isole Vergini Americane",
    "id": "Kepulauan Virgin Amerika",
    "ja": "アメリカ領ヴァージン諸島",
    "th": "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
    "pt": "Ilhas Virgens Americanas"
  }
}
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavigationService } from 'src/app/navigation.service';

@Component({
  selector: 'app-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.css', '../../../app.component.css'],
})
export class AppBannerComponent implements OnInit {

  isVisible = false

  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    private router: Router,
    private navigationService: NavigationService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.navigationService.showAppBanner$.subscribe(
        show => this.isVisible = show
      );
    }
  }

  goToAppStore() {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
        window.location.href = `https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone`;
      } else {
        window.location.href = `https://play.google.com/store/apps/details?id=app.alltours`;
      }
    }
  }

}

<div style="background-color: #fafafa; min-height: 95vh; padding-bottom: 64px">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <div class="cardContainer" *ngIf="tour == undefined">
          <div class="tourImageHolder">
            <img src="../../../../assets/images/alltours/grey_rectangle.png" class="tourImage shimmer"
              alt="Tour image placeholder" />
          </div>
          <div>
            <div>
              <div style="padding-left: 16px; padding-right: 16px; padding-top: 8px;">
                <p class="tourTitle shimmer" style="width: 100%;">
                  ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----
                </p>
              </div>
              <div style="background: #0008;" class="purchasedContainer">
                <div>
                  Preparing... <span class="material-symbols-rounded"
                    style="animation: spin 1s linear infinite; vertical-align: middle;">sync</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cardContainer" *ngIf="tour != undefined">
          <div class="tourImageHolder">
            <img src="{{ tour.image.medium }}" class="tourImage"
              onerror="this.onerror=null; this.src='../../../assets/images/alltours/placeholder.png'"
              alt="Tour Image" />
          </div>
          <div>
            <div>
              <p class="tourTitle" [innerHTML]="tourTitle"></p>
              <div [ngStyle]="{'background': purchased ? '#657728' : '#0008'}" class="purchasedContainer">
                <div *ngIf="purchased">
                  Purchased <span class="material-symbols-rounded" style="margin-left: 4px;">check</span>
                </div>
                <div *ngIf="!purchased">
                  Preparing... <span class="material-symbols-rounded"
                    style="animation: spin 1s linear infinite; vertical-align: middle;">sync</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center; color: #0008; margin-bottom: 32px;">
      Next steps...
    </div>
    <div class="row">
      <div class="col-12" *ngIf="!needsRedeeming">
        <div style="max-width: 500px; margin: auto;">
          <div style="display: flex;" (click)="goToApp()" class="instructionRow highlightedRow">
            <img src="../../../../assets/images/alltours/{{storeIcon}}.png" class="purchasedImage"
              alt="App store download button" />
            <div>
              <b class="primaryText">Download the AllTours App</b>
              <p class="secondaryText">It shouldn't take long.</p>
              <img class="storeQrCode" src="../../../../assets/images/alltours/{{storeQrCode}}.png"
                alt="QR code to download app" />
            </div>
            <div class="material-symbols-rounded icon"
              style="margin-left: auto; font-size: 16px; align-content: center; padding: 12px; color: #00f;">
              arrow_forward_ios</div>
          </div>
          <div style="display: flex;" class="instructionRow">
            <div class="material-symbols-rounded icon purchasedImage">account_circle</div>
            <div>
              <b class="primaryText">Login with the same account</b>
              <p class="secondaryText">{{ loginText }}</p>
            </div>
          </div>
          <div style="display: flex;" class="instructionRow">
            <div class="material-symbols-rounded icon purchasedImage">play_circle</div>
            <div>
              <b class="primaryText">Enjoy Your Tour!</b>
              <p class="secondaryText">Once logged in, you can start your tour.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="needsRedeeming">
        <div style="max-width: 500px; margin: auto; text-align: center;">
          <p style="margin-bottom: 16px; color: #333;">
            <b>Copy</b> the redemption code
          </p>

          <div class="redemptionBox" (click)="copyCode()">
            {{bookingId}}
            <span class="material-symbols-rounded copyIcon" *ngIf="!copied">content_copy</span>
            <span class="material-symbols-rounded copyIcon" *ngIf="copied">check</span>
          </div>

          <p style="margin-bottom: 16px; color: #333;">Then download the AllTours app & enter your code</p>

          <button (click)="goToApp()" class="ctaBtn">
            Get App
          </button>
          <img class="storeQrCode smallQrCode" src="../../../../assets/images/alltours/{{storeQrCode}}.png"
            alt="QR code to download app" />
        </div>
      </div>
    </div>
  </div>
  <div class="bottomContactRow">
    <p style="text-align: center; margin: 32px; color: #0008">Need help? <a href="mailto:kes@alltours.app">Contact
        us</a></p>
  </div>
</div>
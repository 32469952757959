import { environment } from "src/environments/environment";
import { getAnalytics, logEvent } from '@angular/fire/analytics';

export function logEventMethod(eventName: string, eventParams?: { [key: string]: any; }) {
  if (environment.production && typeof window !== undefined) {
    try {
      logEvent(getAnalytics(), eventName, eventParams);
      console.log('Event logged:', eventName);
    } catch (error) {
      console.error('Failed to log event:', eventName, error);
    }
  }
} 
import { Component, OnInit, ViewEncapsulation, Inject, PLATFORM_ID, inject, ExperimentalPendingTasks as PendingTasks } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import countryNamesJson from '../../../../assets/json/countries.json';
import citiesJson from '../../../../assets/json/mini_cities.json';
import topCitiesJson from '../../../../assets/json/top_tourist_cities.json';
import { EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import stringsJson from '../../../../assets/strings/strings.json';
import { setSeoText, stringFormat } from '../../../utils/utils';
// import exampleTour from '../../../../assets/json/example_tour.json';
import { fadeInUpAnimation, fadeAnimation } from 'src/app/utils/animations';
import { UserService } from '../user.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { getFunctions, httpsCallableData } from '@angular/fire/functions';
import { collection, doc, getDoc, getDocs, getFirestore, limit, query, where } from '@angular/fire/firestore';
import { SeoService } from 'src/app/seo.service';
import { logEventMethod } from '../../../utils/logging';
@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css', '../../../app.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInUpAnimation, fadeAnimation]
})
export class CityComponent implements OnInit {
  jsonLD: SafeHtml;

  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private userService: UserService,
    @Inject(PLATFORM_ID) public platformId: Object,
    @Inject(DOCUMENT) public _document: Document,
    private seoService: SeoService
  ) { }
  private pendingTasks = inject(PendingTasks);
  cities = citiesJson.cities;
  countryName;
  cityName;
  city;
  countryCode;
  topCountryCities;
  countryCities
  isCityPage
  localisedCityName;
  article;
  strings;
  title;
  subTitle;
  defaultArticle;
  language;
  tours = [];
  showCta1 = false;
  loadingTours = true;
  loadingCity = true;
  cityLandmarks = [];
  potentialTours = [];
  serverstate = {};

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.language = window.location.hostname.split(".")[0];
    }
    if (this.language == undefined || this.language.length > 2) this.language = "en"
    this.strings = JSON.parse(JSON.stringify(stringsJson))["default"][this.language]
    this.countryName = this.route.snapshot.paramMap.get("countryName")?.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    this.cityName = this.route.snapshot.paramMap.get("cityName")
    this.isCityPage = this.cityName != undefined

    if (this.isCityPage) {
      this.setDefaultSeoText()
      this.getCity()
      this.getArticle()
    } else {
      this.getCountry()
      this.setDefaultSeoText()
    }
    this.loadingCity = false
    this.subTitle = this.strings.explore_at_own_pace
    this.defaultArticle = this.strings.default_article
    this.article = this.defaultArticle
    this.getTours()
  }

  setDefaultSeoText() {
    if (this.isCityPage) {
      this.seoService.setCityPageSeo(this.cityName)
    } else {
      this.seoService.setCountryPageSeo(this.countryCode)
    }
  }

  getCountry() {
    const foundCity = this.cities.filter(c => c != undefined).find((c) => c.country?.toLowerCase() == this.countryName?.toLowerCase());

    if (!foundCity) {
      console.error(`Country not found: ${this.countryName}`);
      this.router.navigate(['/404']);
      return;
    }

    const { country, countryCode } = foundCity;
    this.title = stringFormat(this.strings.city_audio_tours_in_language, country);
    this.countryCode = countryCode;
    this.countryCities = this.cities.filter(c => c.countryCode?.toLowerCase() == countryCode?.toLowerCase());
    this.topCountryCities = this.countryCities.slice(0, 6);
    this.countryCities = this.countryCities.slice(6, this.countryCities.length).sort((a, b) => a.name.localeCompare(b.name));
  }

  async getCity() {
    const taskCleanup = this.pendingTasks.add();
    let cityName = this.cityName.toLowerCase().replace("%20", " ")
    console.log("cityName", cityName)
    this.city = this.cities.find((c) =>
      ((c.names != undefined && c.names[this.language] != undefined && c.names[this.language].toLowerCase() == cityName) || c.name.toLowerCase() == cityName)
      && c.country.toLowerCase() == this.countryName.toLowerCase()
    )
    if (this.city == undefined) {
      this.city = this.cities.find((c) =>
        (c.names[this.language].toLowerCase() == cityName || c.name.toLowerCase() == cityName)
      )
      if (this.city == undefined) {
        console.error(`City not found: ${cityName}`);
        this.router.navigate(['/404']);
        taskCleanup();
        return;
      }
    }
    this.title = stringFormat(this.strings.city_audio_tours_in_language, this.city.names[this.language] ?? this.city.name)
    this.localisedCityName = this.city.names != undefined ? (this.city.names[this.language] ? this.city.names[this.language] : this.city.name) : this.city.name
    let cityDocData = (await getDoc(doc(getFirestore(), 'cities', this.city.id.toString()))).data();
    if (cityDocData) {
      if (cityDocData != undefined) {
        this.cityLandmarks = cityDocData.allLandmarks
        this.potentialTours = cityDocData.potentialTours
        this.city.image = cityDocData.image
      }
    }
    taskCleanup();
  }

  async getTours() {
    const taskCleanup = this.pendingTasks.add();
    // this.loadingTours = true;
    try {
      var tourQuery, potentialQuery;
      if (this.isCityPage) {
        // Turn into http requests that can be cached but cache only for 24 hours
        tourQuery = getDocs(query(collection(getFirestore(), 'guided-tours'), where('city.id', '==', parseInt(this.city.id)), where('landmarkCount', '>', 4), limit(10)))
        potentialQuery = getDocs(query(collection(getFirestore(), 'potential-tours'), where('city.id', '==', parseInt(this.city.id)), where("generated", "==", false), limit(10)))
      } else {
        tourQuery = getDocs(query(collection(getFirestore(), 'guided-tours'), where('city.countryCode', '==', this.countryCode), where('landmarkCount', '>', 4), limit(10)))
        potentialQuery = getDocs(query(collection(getFirestore(), 'potential-tours'), where('city.countryCode', '==', this.countryCode), where("generated", "==", false), limit(10)))
      }

      let [toursResponse, potentialToursResponse] = await Promise.all([tourQuery, potentialQuery])

      let tours = toursResponse.docs.map(doc => doc.data())
      let potentialTours = potentialToursResponse.docs.map(doc => doc.data())
        .filter(potentialTour => !tours.find(tour => tour.title == potentialTour.title))

      this.tours = [...tours, ...potentialTours].filter(t => t.guide != undefined && t.id != undefined)

      this.tours.sort((a, b) => {
        const ratingA = a.ratings?.ratingSum ?? 0;
        const ratingB = b.ratings?.ratingSum ?? 0;
        return ratingB - ratingA
      });

      this.setTourSeoText()

    } catch (e) {
      console.error(e)
    }
    this.loadingTours = false;
    taskCleanup();
  }

  setTourSeoText() {
    let place = this.isCityPage ? this.localisedCityName : this.countryName

    if (this.tours != undefined && this.tours.length > 0) {
      let landmarks = this.tours.map(tour => tour.landmarks.filter(l => l.latLng != undefined).map(landmark => landmark.title)).flat()
      let landmarkCounts = {}
      landmarks.forEach(landmark => {
        if (landmarkCounts[landmark] == undefined) {
          landmarkCounts[landmark] = 1
        } else {
          landmarkCounts[landmark] += 1
        }
      })
      let mostCommonLandmarks = Object.keys(landmarkCounts).sort((a, b) => landmarkCounts[b] - landmarkCounts[a]).slice(0, 4)

      var title = undefined
      var description = undefined

      if (this.tours.length > 2 && this.tours.length <= 10) {
        title = `${place} Audio Tours (TOP ${this.tours.length})`
      } else if (this.tours.length > 10) {
        title = `${place} Audio Tours (${this.tours.length} Available)`
      }

      if (this.tours.length > 1) {
        description = `Discover ${place} with ${this.tours.length}+ self-guided audio tours.`
        description += " Tours include popular landmarks such as " + mostCommonLandmarks.join(", ") + " and more."
      }
      this.seoService.setSeoText(title, description)
    }
  }

  getSafeHTML(value: {}) {
    const json = JSON.stringify(value, null, 2);
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getArticle() {
    return new Promise((resolve) => {
      var data = null;
      if (topCitiesJson.cities.find(c => c.id == this.city.id) != null) {
        try {
          const getAllToursArticle = httpsCallableData(getFunctions(), 'getAllToursArticle');
          getAllToursArticle({
            language: this.language,
            cityId: this.city.id,
          }).subscribe((result: any) => {
            data = result;
            if (data == undefined || data == null) {
              this.article = this.defaultArticle;
            } else {
              this.article = data.replace(/<title>[^]*<\/title>/g, '');
            }
            resolve(data);
          });
        } catch (e) {
          console.error(e);
          data = null;
          if (data == undefined || data == null) {
            this.article = this.defaultArticle;
          } else {
            this.article = data.replace(/<title>[^]*<\/title>/g, '');
          }
          resolve(data);
        }
      } else {
        this.article = this.defaultArticle;
        resolve(data);
      }
    })

  }

  goToApp() {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
        logEventMethod('alltours_cta_click', { type: 'ios' });
        window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
      } else {
        logEventMethod('alltours_cta_click', { type: 'not-ios' });
        window.open('https://play.google.com/store/apps/details?id=app.alltours');
      }
    }
  }

  onLoginToMakeTour() {
    if (this.userService.isLoggedIn.value) {
      this._document.getElementById('downloadAppButton').click();
      this.userService.setCreateTourCityId(this.city.id);
    } else {
      this._document.getElementById('loginButton').click();
    }
  }

  onSuccessfulLogin(event) {
    this._document.getElementById('closeLoginButton').click();
    this._document.getElementById('downloadAppButton').click();
    this.userService.setCreateTourCityId(this.city.id);
  }

  howItWorksCreateTour = [
    {
      title: "Select the landmarks",
      description: "Choose which landmarks you want to include in your tour.",
      icon: "touch_app"
    },
    {
      title: 'Click generate',
      description: "We'll generate the tour for you in less than 2 minutes.",
      icon: "download"
    },
    {
      title: 'Enjoy the tour',
      description: "Listen to the audio guide and explore the city at your own pace.",
      icon: "headset"
    }
  ]
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css', '../../../app.component.css']
})
export class ImageCarouselComponent {
  @Input() images: string[] = [];
  @Input() isFullscreen: boolean = false;
  @Output() imageClick = new EventEmitter<number>();

  private _currentIndex: number = 0;
  imgLoaded = false;

  @Input()
  set currentIndex(value: number) {
    if (value >= 0 && value < this.images.length) {
      this._currentIndex = value;
    }
  }

  get currentIndex(): number {
    return this._currentIndex;
  }

  next() {
    this.setIndex((this.currentIndex + 1) % this.images.length);
  }

  previous() {
    this.setIndex((this.currentIndex - 1 + this.images.length) % this.images.length);
  }

  goToImage(index: number) {
    this.setIndex(index);
  }

  setIndex(index: number) {
    this.imgLoaded = false;
    this.currentIndex = index;
  }
} 
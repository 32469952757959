export function stringFormat(template: string, ...args: any[]) {
  return template.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
}

export function capitalise(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export function setSeoText(_document, title, description, image = "https://alltours.app/assets/images/alltours/screenshots/audio.jpg") {
  console.log('setting seo text', title, description)
  if (_document) {
    if (title != undefined) {
      _document.title = title;
    }
    if (_document.head.children.namedItem('description') && description != undefined) {
      _document.head.children.namedItem('description')['content'] = description;
    }

    setOgProperties(_document, {
      'og:title': title,
      'og:description': description != undefined ? description.split(/(?<=[.!?])\s+/)[0] : "Entertaining self guided audio tours.",
      'og:image': image
    });

  }
}

function setOgProperties(_document, properties) {
  Object.entries(properties).forEach(([property, content]) => {
    let metaTag = _document.querySelector(`meta[property="${property}"]`);
    if (metaTag) {
      metaTag.setAttribute('content', content as string);
    } else {
      metaTag = _document.createElement('meta');
      metaTag.setAttribute('property', property);
      metaTag.setAttribute('content', content as string);
      _document.head.appendChild(metaTag);
    }
  });
}

export function distanceBetween(latLng1, latLng2) {
  return Math.sqrt(
    Math.pow(latLng1[0] - latLng2[0], 2) +
    Math.pow(latLng1[1] - latLng2[1], 2)
  );
}
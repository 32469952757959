<div *ngIf="tour == undefined" class="row cardContainer">
  <div class="col-4 col-md-12 tourImageHolder">
    <img src="../../../../assets/images/alltours/grey_rectangle.png" class="tourImage shimmer" style="width: 100%;"
      alt="Tour preview placeholder" />
  </div>
  <div class="col-8 col-md-12">
    <div class="previewContainer">
      <p class="shimmer tourTitle">Example title Example title Example title Example title</p>
      <div style="margin: 8px;" class="shimmer">
        Example rating
      </div>
      <div class="tourDescription">
        <div style="width: 100%;">
          <div class="shimmer">
            Example Example
          </div>
          <div class="shimmer" style="margin-top: 8px;">
            Example Example
          </div>
        </div>
      </div>
      <div class="shimmer tourPrice" style="position: absolute;">$2.999</div>
    </div>
  </div>
</div>

<div *ngIf="tour != undefined" class="row cardContainer">
  <div class="col-4 col-md-12 tourImageHolder">
    <a class="noUnderline" style="width: 100%;"
      href="/tour/{{ tour.city.countryName.toLowerCase() }}/{{ tour.city.name.toLowerCase() }}/{{tour.id}}">
      <img src="{{ tour.image != undefined ? tour.image.medium : '' }}" class="tourImage"
        alt="Main image for tour: {{ tourTitle }}"
        onerror="this.onerror=null; this.src='../../../assets/images/alltours/placeholder.png'" />
    </a>
    <div class="playBtn" *ngIf="!isPotentialTour">
      <span *ngIf="playingState == 0" (click)="playAudio()"
        class="material-symbols-rounded icons innerPlayBtn">play_arrow</span>
      <div *ngIf="playingState == 1" class="loaderHolder innerPlayBtn">
        <div class="loader"></div>
      </div>
      <span *ngIf="playingState == 2" (click)="playAudio()"
        class="material-symbols-rounded icons innerPlayBtn">stop</span>
    </div>
  </div>
  <div class="col-8 col-md-12 outerPreviewContainer">
    <a class="noUnderline"
      href="/tour/{{ tour.city.countryName.toLowerCase() }}/{{ tour.city.name.toLowerCase() }}/{{tour.id}}">
      <div class="previewContainer">
        <p class="tourTitle">{{tourTitle}}</p>
        <div class="tourRating"
          *ngIf="tour.ratings != undefined && tour.ratings.ratingCount > 0 && tour.ratings.ratingAvg > 1">
          <div style="color: #ccc; font-size: 16px;">
            <span class="material-symbols-rounded" [ngClass]="{'checked': tour.ratings.ratingAvg >= 0.5}">star</span>
            <span class="material-symbols-rounded" [ngClass]="{'checked': tour.ratings.ratingAvg >= 1.5}">star</span>
            <span class="material-symbols-rounded" [ngClass]="{'checked': tour.ratings.ratingAvg >= 2.5}">star</span>
            <span class="material-symbols-rounded" [ngClass]="{'checked': tour.ratings.ratingAvg >= 3.5}">star</span>
            <span class="material-symbols-rounded" [ngClass]="{'checked': tour.ratings.ratingAvg >= 4.5}">star</span>
          </div>
          <div style="margin-left: 4px; align-content: center;">
            <span class="tourRatingCount" style="font-size: 12px; color: #0008;">{{tour.ratings.ratingCount}}</span>
          </div>
        </div>
        <div class="tourDescription">
          <div style="width: 100%;">
            <div *ngIf="tour.totalTourDuration != undefined">
              <span class="material-symbols-rounded infoIcon">schedule</span>
              <span>{{((tour.totalTourDuration) / 60) | number: '1.0-0'}} minutes</span>
            </div>
            <div style="margin-top: 8px;">
              <span class="material-symbols-rounded infoIcon">location_on</span>
              <span>{{(tour.routeInstructions.distance / 1000) | number: '1.1-1'}} km walk</span>
            </div>
          </div>
        </div>
        <div *ngIf="!tour.purchased && localPrice != undefined && !hasPurchasedTour" class="tourPrice">
          {{localPrice.price}}</div>
        <div *ngIf="hasPurchasedTour" class="tourPrice">Purchased</div>
      </div>
    </a>
  </div>
</div>

<style>
  .material-symbols-rounded {
    font-size: inherit;
    line-height: inherit;
    vertical-align: middle;
  }
</style>
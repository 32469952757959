import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { CommonModule } from '@angular/common';
import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { AllToursHomeComponent } from './alltours-app/user/home/all-tours-home.component';
import { TourComponent } from './alltours-app/user/tour/tour.component';
import { GetTourComponent } from './alltours-app/user/get-tour/get-tour.component';
import { PolicyComponent } from './alltours-app/user/policy/policy.component';
import { FormatTime } from './utils/pipes/format-time-pipe';
import { ProductComponent } from './alltours-app/admin/product/product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminLoginComponent } from './alltours-app/admin/admin-login/admin-login.component';
import { AdminAuthGuard, AdminAuthService } from './alltours-app/admin/admin-auth-service';
import { PartnerAuthGuard, PartnerService } from './alltours-app/partner/partner-service';
import { UserLoginComponent } from './alltours-app/user/user-login/user-login.component';
import { UserProfileComponent } from './alltours-app/user/user-section/user-profile/user-profile.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UnlimitedComponent } from './alltours-app/user/unlimited/unlimited.component';
import { BetaComponent } from './alltours-app/user/beta/beta.component';
import { InnerContactUsComponent } from './alltours-app/user/inner-contact-us/inner-contact-us.component';
import { SearchComponent } from './alltours-app/user/search/search.component';
import { Router, RouterModule } from '@angular/router';
import { LandmarkMapComponent } from './alltours-app/user/landmark-map/landmark-map.component';
import { environment } from '../environments/environment';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { DeleteAccountComponent } from './alltours-app/user/delete-account/delete-account.component';
import { ContactUsComponent } from './alltours-app/user/contact-us/contact-us.component';
import { TourReviewComponent } from './alltours-app/user/tour-review/tour-review.component';
import { CitiesComponent } from './alltours-app/user/cities/cities.component';
import { provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideFirebaseApp } from '@angular/fire/app';
import { provideAuth } from '@angular/fire/auth';
import { provideFirestore } from '@angular/fire/firestore';
import { provideFunctions } from '@angular/fire/functions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { BecomeAPartnerComponent } from './alltours-app/partner/become-a-partner/become-a-partner.component';
import { AppBannerComponent } from './alltours-app/user/app-banner/app-banner.component';
import { CityPreviewComponent } from './alltours-app/user/city-preview/city-preview.component';
import { CityComponent } from './alltours-app/user/city/city.component';
import { DownloadAppComponent } from './alltours-app/user/download-app/download-app.component';
import { TermsComponent } from './alltours-app/user/terms/terms.component';
import { TourPreviewComponent } from './alltours-app/user/tour-preview/tour-preview.component';
import { TourPurchasedComponent } from './alltours-app/user/tour-purchased/tour-purchased.component';
import { TourReviewsComponent } from './alltours-app/user/tour-reviews/tour-reviews.component';
import { UserSectionComponent } from './alltours-app/user/user-section/user-section.component';
import { UserComponent } from './alltours-app/user/user.component';
import { UserAuthGuard, UserService } from './alltours-app/user/user.service';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { PartnerAdComponent } from './alltours-app/partner/become-a-partner/partner-ad/partner-ad.component';
import { NotFoundComponent } from './alltours-app/user/not-found/not-found.component';
import { ImageCarouselComponent } from './alltours-app/user/image-carousel/image-carousel.component';
import { AppPromoComponent } from './alltours-app/user/app-promo/app-promo.component';
import { BokunPurchaseComponent } from './alltours-app/user/bokun-purchase/bokun-purchase.component';
import { GoToTourComponent } from './alltours-app/user/gototour/gototour.component';
const firebaseConfig = { "projectId": "explorer-f45e6", "appId": "1:466547680272:web:93721e377d2d6e51731695", "storageBucket": "explorer-f45e6.appspot.com", "locationId": "europe-west2", "apiKey": "AIzaSyCedN1xnkghs3CLmwCm95cICJiEqwPnLW4", "authDomain": "explorer-f45e6.firebaseapp.com", "messagingSenderId": "466547680272", "measurementId": "G-3C4N7SPFPQ" };

@NgModule({
  declarations: [
    AppComponent,
    PolicyComponent,
    AllToursHomeComponent,
    CitiesComponent,
    CityComponent,
    TermsComponent,
    TourPreviewComponent,
    TourComponent,
    TourReviewComponent,
    TourReviewsComponent,
    GetTourComponent,
    ContactUsComponent,
    PolicyComponent,
    FormatTime,
    ProductComponent,
    DeleteAccountComponent,
    UnlimitedComponent,
    BetaComponent,
    AdminLoginComponent,
    BecomeAPartnerComponent,
    TourPurchasedComponent,
    UserLoginComponent,
    UserProfileComponent,
    UserSectionComponent,
    UserComponent,
    InnerContactUsComponent,
    SearchComponent,
    LandmarkMapComponent,
    DownloadAppComponent,
    CityPreviewComponent,
    AppBannerComponent,
    PartnerAdComponent,
    NotFoundComponent,
    ImageCarouselComponent,
    AppPromoComponent,
    BokunPurchaseComponent,
    GoToTourComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1Ijoia2VzLWF0LWV4cGxvcmVyIiwiYSI6ImNsYWY5MjMzejEyZWczb2xkaG55M25zZG4ifQ.TV4Rb-vPMqZzP1XFA6cCGw',
    }),
    BrowserAnimationsModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterModule
  ],
  providers: [
    AdminAuthGuard,
    AdminAuthService,
    PartnerAuthGuard,
    UserAuthGuard,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    provideHttpClient(withFetch())
  ],
})
export class AppModule { } 

import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private showAppBannerSubject = new BehaviorSubject<boolean>(false);
  showAppBanner$ = this.showAppBannerSubject.asObservable();

  // Routes where we don't want to show the app banner
  private readonly showBannerRoutes = [
    '/',
    '/cities'
  ];

  constructor(private router: Router) {
  }

  initNavigationService() {
    this.setupRouteListener();
  }

  private setupRouteListener() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      console.log("Navigation event", event.urlAfterRedirects)
      const shouldShowBanner = this.showBannerRoutes.some(route =>
        event.urlAfterRedirects == route
      );
      this.setAppBannerVisibility(shouldShowBanner);
    });
  }

  // Method to manually control banner visibility
  setAppBannerVisibility(show: boolean) {
    console.log("Show banner", show)
    this.showAppBannerSubject.next(show);
  }
} 
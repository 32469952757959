import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LocalStorageService, StorageKey } from 'src/app/local-storage.service';
import { getFunctions, httpsCallableData } from '@angular/fire/functions';
import { doc, getFirestore, getDoc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  constructor(private localStorage: LocalStorageService, @Inject(PLATFORM_ID) public platformId: Object) {
    this.getLanguagesForTours()
  }

  getLanguagesForTours() {
    let localLanguages = this.localStorage.getObject(StorageKey.TOUR_LANGUAGES);
    if (localLanguages) return Promise.resolve(localLanguages);

    return new Promise((resolve, reject) => {
      httpsCallableData(getFunctions(), 'getGuideLanguagesV2')()
        .subscribe((languages: any) => {
          const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
          const formattedLanguages = languages.map((lang: string) => ({
            name: languageNames.of(lang.split('-')[0]),
            nativeName: new Intl.DisplayNames([lang.split('-')[0]], { type: 'language' }).of(lang.split('-')[0]),
            code: lang,
            language: lang.split('-')[0],
            country: lang.split('-')[1],
          }));

          formattedLanguages.sort((a, b) => {
            const aIndex = this.topLanguages.indexOf(a.code);
            const bIndex = this.topLanguages.indexOf(b.code);

            if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;
            if (aIndex !== -1) return -1;
            if (bIndex !== -1) return 1;
            return a.name.localeCompare(b.name);
          });

          this.localStorage.setObject(StorageKey.TOUR_LANGUAGES, formattedLanguages);
          resolve(formattedLanguages);
        });
    });
  }

  async getTour(tourId: string, language = undefined) {
    if (!tourId) {
      console.error('Tour ID is required');
      return null;
    }
    const getTour = httpsCallableData(getFunctions(), 'getTourV2');
    const result: any = await getTour({ tourId, language }).toPromise();
    return result;
  }

  topLanguages = [
    "en-US", // English - United States
    "es-ES", // Spanish - Spain
    "zh-CN", // Chinese - China
    "fr-FR", // French - France
    "de-DE", // German - Germany
    "it-IT", // Italian - Italy
    "pt-PT", // Portuguese - Portugal
    "ja-JP", // Japanese - Japan
    "nl-NL", // Dutch - Netherlands
    "ru-RU", // Russian - Russia
    "ko-KR", // Korean - South Korea
    "ar-SA", // Arabic - Saudi Arabia
    "hi-IN", // Hindi - India
  ];

}

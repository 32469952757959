import { Component, Inject, Input, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Output, EventEmitter } from '@angular/core';
import { getFunctions, httpsCallableData } from '@angular/fire/functions';
import { Auth, createUserWithEmailAndPassword, GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, signInWithPopup } from '@angular/fire/auth';
import { DOCUMENT } from '@angular/common';
import { getAnalytics } from '@angular/fire/analytics';
import { logEventMethod } from '../../../utils/logging';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css', '../../../app.component.css'],
})
export class UserLoginComponent implements OnInit {
  constructor(private userService: UserService, private auth: Auth, @Inject(DOCUMENT) public _document: Document,) { }

  @Input() loginMessage = '';
  @Output() successfulLogin = new EventEmitter<boolean>();

  emailLoginState = EmailLoginState.NOT_SELECTED;
  EmailLoginState = EmailLoginState;
  loggingInState = LoggingInState.NOT_LOGGING_IN;
  LoggingInState = LoggingInState;

  ngOnInit() {
  }

  googleLogin() {
    this.loggingInState = LoggingInState.LOGGING_IN;
    var provider = new GoogleAuthProvider();
    this.signInWithProvider(provider);
    logEventMethod('login_click');
  }

  appleLogin() {
    this.loggingInState = LoggingInState.LOGGING_IN;
    var provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    this.signInWithProvider(provider);
  }

  signInWithProvider(provider) {
    logEventMethod('login_click');
    signInWithPopup(this.auth, provider).then(result => {
      this.onLoginSuccess(result.user);
    })
      .catch(error => {
        this.onLoginError(error);
      });
  }

  createAccountEmail(email, password) {
    createUserWithEmailAndPassword(this.auth, email, password).then(userCredential => {
      this.onLoginSuccess(userCredential.user);
    })
      .catch(error => {
        this.onLoginError(error);
        this.emailLoginState = EmailLoginState.UNKNOWN_EMAIL;
      });
  }

  loginEmailPassword(email, password) {
    signInWithEmailAndPassword(this.auth, email, password).then(userCredential => {
      this.onLoginSuccess(userCredential.user);
    })
      .catch(error => {
        this.onLoginError(error);
        this.emailLoginState = EmailLoginState.KNOWN_EMAIL;
      });
  }

  emailLogin() {
    this.emailLoginState = EmailLoginState.INVALID_EMAIL;
    setTimeout(() => {
      this.setupForm();
    }, 500);
    logEventMethod('login_click');
  }

  closeLoginModal() {
    this._document.getElementById('closeLoginBtn').click();
    this.loggingInState = LoggingInState.NOT_LOGGING_IN;
  }

  async onLoginSuccess(user) {
    logEventMethod('login_success');
    this.loggingInState = LoggingInState.SETTING_UP;
    await this.userService.onLogin(user);
    this.loggingInState = LoggingInState.LOGGED_IN;
    this.successfulLogin.emit(true);
    setTimeout(() => {
      this.closeLoginModal();
    }, 2000);
  }

  onLoginError(error) {
    alert(error);
    this.loggingInState = LoggingInState.NOT_LOGGING_IN;
    console.error('loginError', error);
  }

  returnToLoginOptions() {
    this.emailLoginState = EmailLoginState.NOT_SELECTED;
  }

  setupForm() {
    var form = this._document.getElementById('emailForm')
    let thisRef = this
    form.addEventListener('submit', function (event) {
      event.preventDefault()
      const userDetails = Object.fromEntries(new FormData(event.target as HTMLFormElement) as any);
      if (
        thisRef.emailLoginState == EmailLoginState.UNKNOWN_EMAIL &&
        userDetails.password != userDetails.confirmPassword
      ) {
        alert('Passwords do not match');
        return;
      }

      logEventMethod('login_click');

      if (thisRef.emailLoginState === EmailLoginState.UNKNOWN_EMAIL) {
        thisRef.createAccountEmail(userDetails.emailAddress, userDetails.password);
      } else {
        thisRef.loginEmailPassword(userDetails.emailAddress, userDetails.password);
      }
      thisRef.loggingInState = LoggingInState.LOGGING_IN;
    },
      false
    );
  }

  validEmailRegex = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  async onEmailChange(event: Event) {
    const email = (event.target as HTMLInputElement).value;
    this.emailLoginState =
      email.trim() === ''
        ? EmailLoginState.INVALID_EMAIL
        : !this.validEmailRegex.test(email)
          ? EmailLoginState.INVALID_EMAIL
          : EmailLoginState.CHECKING_EMAIL;

    if (this.emailLoginState === EmailLoginState.CHECKING_EMAIL) {
      const getUserByEmail = httpsCallableData(getFunctions(), 'getUserByEmail');
      getUserByEmail({ email: email }).subscribe((result: any) => {
        this.emailLoginState = !result ? EmailLoginState.UNKNOWN_EMAIL : EmailLoginState.KNOWN_EMAIL;
      })
    }
  }
}

enum EmailLoginState {
  NOT_SELECTED,
  INVALID_EMAIL,
  CHECKING_EMAIL,
  KNOWN_EMAIL,
  UNKNOWN_EMAIL,
}

enum LoggingInState {
  NOT_LOGGING_IN,
  LOGGING_IN,
  SETTING_UP,
  LOGGED_IN,
}
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  template: `
    <div class="not-found-container">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>The page you're looking for doesn't exist or has been moved.</p>
      <button mat-raised-button color="primary" (click)="goHome()">Go to Home</button>
    </div>
  `,
  styles: [`
    .not-found-container {
      text-align: center;
      padding: 50px 20px;
      min-height: 60vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    h1 {
      font-size: 72px;
      margin: 0;
      color: #f44336;
    }
    h2 {
      font-size: 32px;
      margin: 20px 0;
    }
    p {
      font-size: 18px;
      color: #666;
      margin-bottom: 30px;
    }
    button {
      font-size: 16px;
      padding: 10px 30px;
    }
  `]
})
export class NotFoundComponent {
  constructor(private router: Router) { }

  goHome() {
    this.router.navigate(['/']);
  }
} 
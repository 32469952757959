<div style="margin: auto; max-width: 500px;">
  <div class="form-group position-relative">
    <div class="input-group" style="border: solid 2px #3b4d00; border-radius: 8px;">
      <input id="searchInput" #searchInput placeholder="Search here" type="text" class="form-control"
        autocomplete="off">
      <button type="button" class="btn btn-secondary"
        style="background-color: #3b4d00; border: 0; color: #fff; padding: 0px; padding-left: 8px; padding-right: 8px;">
        <span *ngIf="!loading" class="material-symbols-rounded">search</span>
        <div *ngIf="loading" style="width: 16px; height: 16px;" class="loader"></div>
      </button>
    </div>
    <div class="dropdown-menu show" style="width: 100%" *ngIf="loading || results.length > 0 || results == undefined">
      <div *ngIf="results != undefined && results.length > 0">
        <a *ngFor="let result of results" href="{{ result.path }}" class="dropdown-item searchResultRow"
          style="display: flex;">
          <div class="searchIcon">
            <span *ngIf="result.type === 'tour'" class="material-symbols-rounded icon">headphones</span>
            <span *ngIf="result.type === 'city'" class="material-symbols-rounded icon">location_city</span>
            <span *ngIf="result.type === 'country'" class="material-symbols-rounded icon">flag</span>
          </div>
          <div class="searchResultText">
            {{ result.title }}<span *ngIf="result.type == 'city'">, {{ result.description }}</span>
          </div>
        </a>
      </div>
      <div *ngIf="results != undefined && results.length === 0 && !loading && query.length > 0" style="width: 100%">
        <div class="dropdown-item searchResultRow">
          <div class="searchResultText" style="text-align: center;">
            No results found<br />try searching for a city
          </div>
        </div>
      </div>
      <div *ngIf="loading" style="width: 100%">
        <div class="dropdown-item searchResultRow">
          <div class="searchResultText" style="text-align: center;">
            Searching for more results...
          </div>
        </div>
      </div>
      <div *ngIf="results == undefined" style="width: 100%">
        <div class="dropdown-item searchResultRow">
          <div class="searchResultText" style="text-align: center;">
            Sorry, there was an error during your search 😢
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AllToursHomeComponent } from './alltours-app/user/home/all-tours-home.component';
import { PolicyComponent } from './alltours-app/user/policy/policy.component';
import { CitiesComponent } from './alltours-app/user/cities/cities.component';
import { CityComponent } from './alltours-app/user/city/city.component';
import { TermsComponent } from './alltours-app/user/terms/terms.component';
import { TourComponent } from './alltours-app/user/tour/tour.component';
import { TourReviewsComponent } from './alltours-app/user/tour-reviews/tour-reviews.component';
import { GetTourComponent } from './alltours-app/user/get-tour/get-tour.component';
import { ContactUsComponent } from './alltours-app/user/contact-us/contact-us.component';
import { ProductComponent } from './alltours-app/admin/product/product.component';
import { DeleteAccountComponent } from './alltours-app/user/delete-account/delete-account.component';
import { UnlimitedComponent } from './alltours-app/user/unlimited/unlimited.component';
import { BetaComponent } from './alltours-app/user/beta/beta.component';
import { AdminLoginComponent } from './alltours-app/admin/admin-login/admin-login.component';
import { BecomeAPartnerComponent } from './alltours-app/partner/become-a-partner/become-a-partner.component';
import { PartnerLoginComponent } from './alltours-app/partner/become-a-partner/partner-login/partner-login.component';
import { PartnerSignupComponent } from './alltours-app/partner/become-a-partner/partner-signup/partner-signup.component';
import { PartnerTermsComponent } from './alltours-app/partner/partner-terms/partner-terms.component';
import { TourPurchasedComponent } from './alltours-app/user/tour-purchased/tour-purchased.component';
import { UserProfileComponent } from './alltours-app/user/user-section/user-profile/user-profile.component';
import { UserAuthGuard } from './alltours-app/user/user.service';
import { UserSectionComponent } from './alltours-app/user/user-section/user-section.component';
import { UserComponent } from './alltours-app/user/user.component';
import { PartnerAdComponent } from './alltours-app/partner/become-a-partner/partner-ad/partner-ad.component';
import { NotFoundComponent } from './alltours-app/user/not-found/not-found.component';
import { BokunPurchaseComponent } from './alltours-app/user/bokun-purchase/bokun-purchase.component';
import { GoToTourComponent } from './alltours-app/user/gototour/gototour.component';

const appRoutes: Routes = [
  {
    path: '', component: UserComponent, children: [
      { path: '', component: AllToursHomeComponent },
      { path: 'policy', component: PolicyComponent },
      { path: 'terms', component: TermsComponent },
      { path: 'contact', component: ContactUsComponent },
      { path: 'product', component: ProductComponent },
      { path: 'delete', component: DeleteAccountComponent },
      { path: 'unlimited', component: UnlimitedComponent },
      { path: 'beta', component: BetaComponent },
      { path: 'cities', component: CitiesComponent },
      { path: 'tour/:countryName', component: CityComponent },
      { path: 'tour/:countryName/:cityName', component: CityComponent },
      { path: 'tour/:countryName/:cityName/:tourId', component: TourComponent },
      { path: 'tour/:countryName/:cityName/:tourId/reviews', component: TourReviewsComponent },
      { path: 'gettour', component: GetTourComponent },
      { path: 't/:tourId', component: GoToTourComponent },
      { path: 'tour-purchased/:tourId', component: TourPurchasedComponent },
      { path: 'bokun-purchase/:bookingId', component: BokunPurchaseComponent },
      { path: 'alltours', redirectTo: '' },
      { path: 'map', redirectTo: 'explorer/map' },
      { path: 'tour', redirectTo: 'cities' },
      { path: 'admin-login', component: AdminLoginComponent },
      {
        path: 'user',
        component: UserSectionComponent,
        canActivate: [UserAuthGuard],
        children: [{ path: '', component: UserProfileComponent }],
      },
      { path: '404', component: NotFoundComponent },
    ]
  },
  {
    path: 'admin',
    loadChildren: () => import('./alltours-app/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'partner',
    loadChildren: () => import('./alltours-app/partner/partner.module').then(m => m.PartnerModule)
  },
  {
    path: 'become-a-partner', component: BecomeAPartnerComponent, children: [
      { path: '', component: PartnerAdComponent },
      { path: 'login', component: PartnerLoginComponent },
      { path: 'signup', component: PartnerSignupComponent },
      { path: 'terms', component: PartnerTermsComponent },
    ]
  },
  { path: ':lang/alltours/policy', redirectTo: '/policy' },
  { path: ':lang/alltours/terms', redirectTo: '/terms', pathMatch: 'full' },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

<div style="background-color: #fafafa; min-height: 95vh; padding-bottom: 64px">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="cardContainer" *ngIf="purchases.length == 0">
          <div class="tourImageHolder">
            <img src="../../../../assets/images/alltours/grey_rectangle.png" class="tourImage shimmer"
              alt="Tour image placeholder" />
          </div>
          <div>
            <div>
              <div style="padding-left: 16px; padding-right: 16px; padding-top: 8px;">
                <p class="tourTitle shimmer" style="width: 100%;">
                  ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----
                </p>
              </div>
              <div style="background: #0008;" class="purchasedContainer">
                <div>
                  Preparing... <div id="preparingLoader" class="loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngFor="let purchase of purchases">
          <div class="cardContainer" *ngIf="purchase.tour != undefined">
            <div class="tourImageHolder">
              <img src="{{ purchase.tour.image.medium }}" class="tourImage"
                onerror="this.onerror=null; this.src='../../../assets/images/alltours/placeholder.png'"
                alt="Tour Image" />
            </div>
            <div>
              <div>
                <p class="tourTitle" [innerHTML]="purchase.tour.title"></p>
                <div class="purchasedContainer" (click)="copyCode(purchase.redemptionCode)" style="cursor: pointer;">
                  <span id="redemptionCode">{{purchase.redemptionCode}}</span>
                  <span class="material-symbols-rounded"
                    *ngIf="!copiedCodes[purchase.redemptionCode]">content_copy</span>
                  <span class="material-symbols-rounded" *ngIf="copiedCodes[purchase.redemptionCode]">check</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div style="max-width: 500px; margin: auto; text-align: center; margin-top: 32px;">
          <div *ngIf="purchases.length > 0">
            <p style="margin-bottom: 32px;">Copy the above code & then...</p>
            <p style="margin-bottom: 16px; color: #333;">Download the AllTours app & enter the code</p>
            <button (click)="goToApp()" class="ctaBtn">
              Get App
            </button>
            <img class="storeQrCode smallQrCode" src="../../../../assets/images/alltours/{{storeQrCode}}.png"
              alt="QR code to download app" />
          </div>
          <div *ngIf="purchases.length == 0">
            <p>Please wait while we prepare your tour...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottomContactRow">
    <p style="text-align: center; margin: 16px; color: #0008">
      Need help? <a href="mailto:kes@alltours.app">Contact us</a>
    </p>
  </div>
</div>
import { getFunctions, httpsCallableData } from '@angular/fire/functions';

export function runFunction(functionName: AllToursFunction, request = undefined) {
  return new Promise((resolve) => {
    try {
      const callableFunction = httpsCallableData(getFunctions(), functionName);
      callableFunction(request).subscribe((result: any) => {
        console.log('runFunction', functionName, request, result)
        resolve(result)
      })
    } catch (error) {
      console.error(error)
      resolve(undefined);
    }
  })

}

export enum AllToursFunction {
  createRedemptionCodeV2 = 'createRedemptionCodeV2',
  createUnlimitedRedemptionCode = 'createUnlimitedRedemptionCode',
  linkUserWithPartner = 'linkUserWithPartner',
  getPartnerStripeAccount = 'getPartnerStripeAccount',
  getPartnerStripeOnboardingLink = 'getPartnerStripeOnboardingLink',
  getPartnerStripeDashboardLink = 'getPartnerStripeDashboardLink',
  searchAll = 'searchAll',
  getTourPrices = 'getTourPrices',
}

<div style="background: #fefcf4">
  <div style="display: flex">
    <div style="width: 100%">
      <picture>
        <source srcset="
            ../../../assets/images/alltours/converted/city-480w.webp 480w,
            ../../../assets/images/alltours/converted/city-800w.webp 800w,
            ../../../assets/images/alltours/converted/city.webp 1200w
          " type="image/webp" sizes="100vw" />
        <source srcset="
            ../../../assets/images/alltours/converted/city-480w.jpg 480w,
            ../../../assets/images/alltours/converted/city-800w.jpg 800w,
            ../../../assets/images/alltours/converted/city.jpg 1200w
          " type="image/jpeg" sizes="100vw" />
        <img style="width: 100%; height: 95vh; object-fit: cover"
          src="../../../assets/images/alltours/converted/city.webp" alt="City background" loading="eager"
          fetchpriority="high" />
      </picture>
    </div>
    <div class="imgCover">
      <div class="landingContent">
        <div class="centralMedia">
          <div id="ctaHolder">
            <h1 id="landingTitle">{{ strings.any_city_any_language }}</h1>
            <p id="landingSubtitle">{{ strings.home_sub_title_text }}</p>
            <app-search></app-search>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align: center; margin-top: 32px">
    <div style="padding: 16px">
      <h2 class="screenshotTitle">{{ strings.top_tours_title }}</h2>
      <p style="color: #0008">{{ strings.top_tours_description }}</p>
    </div>
    <div class="container" style="text-align: center; width: 100%">
      <div class="row" *ngIf="featuredTours.length == 0">
        <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let number of [0,1,2]">
          <app-tour-preview [tour]="undefined"></app-tour-preview>
        </div>
      </div>
      <div class="row" style="place-content: center" [@fadeInUp]="featuredTours.length == 0 ? 'out' : 'in'">
        <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let tour of featuredTours"
          style="padding: 0; text-align: -webkit-center">
          <app-tour-preview [tour]="tour"></app-tour-preview>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div *ngFor="let screenshot of screenshots" class="row screenshotRow" style="color: #303f00; max-height: 2000px">
      <div *ngIf="screenshot.id % 2 == 0" class="col-lg-6 col-sm-12 textHolder">
        <div class="textLeft">
          <h2 class="screenshotTitle">{{ screenshot.title }}</h2>
          <p style="color: #0008">{{ screenshot.text }}</p>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12 screenshotHolder">
        <picture>
          <source srcset="
              {{ screenshot.img.replace('.png', '-480w.webp') }} 480w,
              {{ screenshot.img.replace('.png', '-800w.webp') }} 800w,
              {{ screenshot.img.replace('.png', '.webp') }} 1200w
            " type="image/webp" sizes="(max-width: 576px) 100vw, (max-width: 992px) 50vw, 33vw" />
          <source srcset="
              {{ screenshot.img.replace('.png', '-480w.png') }} 480w,
              {{ screenshot.img.replace('.png', '-800w.png') }} 800w,
              {{ screenshot.img.replace('.png', '.png') }} 1200w
            " type="image/png" sizes="(max-width: 576px) 100vw, (max-width: 992px) 50vw, 33vw" />
          <img style="height: auto; width: 100%; max-height: 600px; object-fit: contain" src="{{ screenshot.img }}"
            alt="{{ screenshot.title }} screenshot" loading="lazy" />
        </picture>
      </div>
      <div *ngIf="screenshot.id % 2 == 1" class="col-lg-6 col-sm-12 order-first order-lg-last textHolder">
        <div class="textRight">
          <h2 class="screenshotTitle">{{ screenshot.title }}</h2>
          <p style="color: #0008">{{ screenshot.text }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
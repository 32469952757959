import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object
  ) { }

  setObject(key: StorageKey, value: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  getObject(key: StorageKey) {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return null;
    }
  }

  setItem(key: any, value: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, value);
      localStorage.setItem(key + "_timestamp", Date.now().toString());
    }
  }

  getItem(key: any, fresh: boolean = false) {
    if (isPlatformBrowser(this.platformId)) {
      if (fresh) {
        let timestamp = localStorage.getItem(key + "_timestamp");
        if (timestamp) {
          let timeAgo = Date.now() - parseInt(timestamp);
          let threeDays = 1000 * 60 * 60 * 24 * 3;
          if (timeAgo > threeDays) {
            return undefined
          }
        } else {
          return undefined
        }
      }
      return localStorage.getItem(key);
    } else {
      return null;
    }
  }

  removeItem(key: StorageKey) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    }
  }
}

export enum StorageKey {
  USER_ID = 'userId',
  PARTNER_ID = 'partnerId',
  USER_EMAIL = 'userEmail',
  STRIPE_ACCOUNT_ID = 'stripeAccountId',
  PARTNERS_PRINTED_ONCE = "partnersPrintedOnce",
  HAS_CLICKED_DOWNLOAD_APP = "hasClickedDownloadApp",
  TOUR_PRICES = "tourPrices",
  TOUR_LANGUAGES = "tourLanguages"
}

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { getFunctions, httpsCallableData } from '@angular/fire/functions';
import { Purchases } from '@revenuecat/purchases-js';
import { LocalStorageService, StorageKey } from 'src/app/local-storage.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PurchasesService {
  purchases: Purchases

  constructor(private storageService: LocalStorageService, @Inject(PLATFORM_ID) public platformId: Object, private http: HttpClient) {
  }

  async initRevenueCat(userId) {
    this.purchases = Purchases.configure("rcb_nderXDuYpUMhwzJsSjhjrvHErJjG", userId);
    let customerInfo = await this.purchases.getCustomerInfo()
  }

  getCheckoutUrl(tourId, priceId, cancelUrl, successUrl, userId, userEmail, languageSelected) {
    return new Promise((resolve) => {
      const createCheckoutSession = httpsCallableData(getFunctions(), 'createCheckoutSession');
      createCheckoutSession({
        tourId: tourId,
        productId: priceId,
        cancelUrl: cancelUrl,
        successUrl: successUrl,
        language: 'en',
        userId: userId,
        email: userEmail,
        languageSelected: languageSelected
      }).subscribe((result: any) => {
        resolve(result);
      });
    })
  }

  getPriceConfig(landmarkCount, currency = "usd"): Promise<any> {
    return new Promise(async (resolve) => {
      let prices = await this.getTourPrices(currency) as any[];
      let priceConfig = undefined;
      prices.forEach(option => {
        if (landmarkCount >= option.min && landmarkCount <= option.max) {
          priceConfig = option;
        }
      });
      if (priceConfig == undefined) {
        priceConfig = prices[prices.length - 1]
      }
      priceConfig.amountNum = parseFloat(priceConfig.amount)
      resolve(priceConfig);
    })
  }

  getLocalPrice(tour) {
    return new Promise(async (resolve) => {
      let landmarkCount = tour.landmarkCount ? tour.landmarkCount : tour.landmarks.filter(l => l.latLng != undefined).length
      let price = await this.getPriceConfig(landmarkCount);
      console.log("price", price, "landmarkCount", landmarkCount)
      resolve(price);
    })

  }

  getTourPrices(currency = "usd"): Promise<any> {
    return new Promise((resolve) => {
      if (isPlatformBrowser(this.platformId)) {
        const pricesJson = this.storageService.getItem(StorageKey.TOUR_PRICES + currency, true);
        if (pricesJson) {
          try {
            resolve(JSON.parse(pricesJson));
            return;
          } catch (e) {
            console.error("Error parsing prices from localStorage:", e);
          }
        }
      } else {
        resolve(this.defaultPricing)
      }

      this.http.post("https://gettourpriceshttp-oxu62toa5q-uc.a.run.app", { currency }).subscribe((response: any) => {
        const prices = response.data
        if (isPlatformBrowser(this.platformId) && prices) {
          this.storageService.setItem(StorageKey.TOUR_PRICES + currency, JSON.stringify(prices));
        }

        resolve(prices || this.defaultPricing);
      }, (error) => {
        console.error("Error fetching tour prices:", error);
        resolve(this.defaultPricing);
      });
    });
  }

  defaultPricing = [{ "id": "guided_tour_6", "currency": "usd", "price": "$5.99", "amount": "5.99", "min": null, "max": null }, { "id": "guided_tour_5", "currency": "usd", "price": "$5.49", "amount": "5.49", "min": null, "max": null }, { "id": "guided_tour_4", "currency": "usd", "price": "$4.99", "amount": "4.99", "min": null, "max": null }, { "id": "guided_tour_3", "currency": "usd", "price": "$4.49", "amount": "4.49", "min": 16, "max": 20 }, { "id": "guided_tour_2", "currency": "usd", "price": "$3.99", "amount": "3.99", "min": 11, "max": 15 }, { "id": "guided_tour_0", "currency": "usd", "price": "$2.99", "amount": "2.99", "min": 0, "max": 5 }, { "id": "guided_tour_1", "currency": "usd", "price": "$3.49", "amount": "3.49", "min": 6, "max": 10 }]

}

import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { isPlatformBrowser } from '@angular/common';
import { Auth } from '@angular/fire/auth';
import { logEventMethod } from '../../../utils/logging';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.css', '../../../app.component.css']
})
export class DownloadAppComponent implements OnInit {

  constructor(private route: ActivatedRoute, private userService: UserService, @Inject(PLATFORM_ID) public platformId: Object, private auth: Auth) { }

  @Input() lastStepToShow

  lastStep
  loginText
  isIphone
  storeIcon
  storeQrCode

  ngOnInit(): void {
    if (this.lastStepToShow == 'createTour') {
      this.lastStep = this.lastStepCreateTour;
    } else if (this.lastStepToShow == 'enjoyTour') {
      this.lastStep = this.lastStepEnjoyTour;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.isIphone = navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)
    }
    this.storeIcon = this.isIphone ? 'appstoreicon' : 'playstoreicon';
    this.storeQrCode = this.isIphone ? 'appstoreqrcode' : 'playstoreqrcode';
    this.userService.user$.subscribe(user => {
      this.createLoginText()
    })
  }

  createLoginText() {
    let user = this.auth.currentUser
    if (user == null) {
      return
    }
    let providerId = user.providerData[0].providerId
    let email = user.email
    switch (providerId) {
      case 'google.com':
        this.loginText = `Using Google (${email})`;
        break;
      case 'apple.com':
        this.loginText = `Using Apple (${email})`;
        break;
      default:
        this.loginText = `Using your email (${email})`;
    }
  }

  goToApp() {
    if (this.isIphone) {
      logEventMethod('post_purchase_download_click', { type: 'ios' });
      window.location.href = 'https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone';
    } else {
      logEventMethod('post_purchase_download_click', { type: 'not-ios' });
      window.location.href = 'https://play.google.com/store/apps/details?id=app.alltours';
    }
  }

  lastStepCreateTour = {
    icon: "play_circle",
    title: "Create your tour",
    description: "You'll be ready to go in a matter of minutes.",
  }

  lastStepEnjoyTour = {
    icon: "play_circle",
    title: "Enjoy your tour",
    description: "Once logged in, you can start your tour.",
  }

}
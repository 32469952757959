import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationService } from 'src/app/navigation.service';
import { logEventMethod } from 'src/app/utils/logging';

interface Review {
  rating: number;
  text: string;
  author: string;
}

@Component({
  selector: 'app-app-promo',
  templateUrl: './app-promo.component.html',
  styleUrls: ['./app-promo.component.css', '../../../app.component.css']
})
export class AppPromoComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) public platformId: Object, private navigationService: NavigationService) { }

  needSpaceForBanner = false

  ngOnInit() {
    this.checkIfNeedSpaceForBanner()
  }

  reviews: Review[] = [
    {
      rating: 5,
      text: 'Excellent app, the ability to create tours anywhere in the world in any language is amazing!',
      author: 'Prateek G.'
    },
    {
      rating: 5,
      text: 'Fantastic app. Easy to navigate, well designed, and very informative. I’ll never travel without my AllTours app again- total game changer! Couldn’t recommend higher. Thank you AllTours 👍',
      author: 'Jane B.'
    }
  ];

  getApp() {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
        window.location.href = `https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone`;
      } else {
        window.location.href = `https://play.google.com/store/apps/details?id=app.alltours`;
      }
    }
  }

  checkIfNeedSpaceForBanner() {
    this.navigationService.showAppBanner$.subscribe(
      show => this.needSpaceForBanner = show
    );
  }

  goToAppStore() {
    if (isPlatformBrowser(this.platformId)) {
      logEventMethod('alltours_cta_click', { type: 'ios' });
      window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
    }
  }

  goToPlayStore() {
    if (isPlatformBrowser(this.platformId)) {
      logEventMethod('alltours_cta_click', { type: 'not-ios' });
      window.open('https://play.google.com/store/apps/details?id=app.alltours');
    }
  }
}

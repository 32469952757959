<div class="carousel-container" *ngIf="images.length > 0">
  <div class="carousel-content">
    <div class="image-container">
      <img [src]="images[currentIndex]" class="carousel-image" (load)="imgLoaded = true" #carouselImage
        alt="Carousel image" />
      <div class="loading-overlay"
        [ngStyle]="{'opacity': imgLoaded ? '0' : '1', 'transition': 'opacity 0.3s ease-in-out'}"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(128,128,128,0.7); display: flex; justify-content: center; align-items: center;">
        <div class="loader">

        </div>
      </div>
    </div>

    <div *ngIf="images.length > 1" class="nav-button prev" (click)="previous()"
      style="width: 20%; cursor: pointer; background: none; height: 100%;">
      <button class="nav-button prev" style="pointer-events: none;">
        <span class="material-symbols-rounded">chevron_left</span>
      </button>
    </div>
    <div *ngIf="images.length > 1" class="nav-button next" (click)="next()"
      style="width: 20%; cursor: pointer; background: none; height: 100%;">
      <button class="nav-button next" style="pointer-events: none;">
        <span class="material-symbols-rounded">chevron_right</span>
      </button>
    </div>

    <div *ngIf="images.length > 1" class="indicators">
      <button *ngFor="let image of images; let i = index" class="indicator-dot" [class.active]="i === currentIndex"
        (click)="goToImage(i)"></button>
    </div>

  </div>
</div>
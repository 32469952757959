<div style="padding: 8px;" *ngIf="isVisible">
  <div
    style="display: flex; align-items: center; padding: 12px; background-color: #000b; color: #fff; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); max-width: 400px; margin: auto;">
    <div style="display: flex; align-items: center; margin-right: 10px;">
      <picture>
        <source srcset="../../../../assets/images/alltours/converted/logo_app.webp" type="image/webp" />
        <img src="../../../../assets/images/alltours/converted/logo_app.png" alt="App Logo" loading="lazy"
          style="width: 40px; height: 40px; object-fit: contain;" />
      </picture>
    </div>

    <div style="flex-grow: 1; text-align: start;">
      <div style="font-size: 15px; font-weight: bold;">AllTours - Audio Tours</div>
      <div style="display: flex; align-items: center; gap: 2px; font-size: 8px;">
        <div style="color: #ccc" *ngFor="let i of [1,2,3,4,5]">
          <span class="material-symbols-rounded checked" style="font-size: 12px;">star</span>
        </div>
        <span style="font-size: 12px; color: #fff8; margin-left: 4px;">4.8</span>
      </div>
    </div>

    <button class="ctaBtn" (click)="goToAppStore()"
      style="padding: 8px 12px; font-size: 14px; font-weight: bold; height: 40px;">Get
      App</button>
  </div>
</div>